h2.MuiTypography-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    border-bottom: 1px solid gray;
    padding-bottom: 8px!important;
}
h2.MuiTypography-root .closebutton {
    /* font-family: 'Proxima Nova Rg';
    font-size: 20px;
    color: gray;
    margin-left: 10px; */
    /* background-color: DodgerBlue; */
    border: none;
    /* color: white; */
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
}
.MuiDialogContent-root {
    margin-bottom: 15px;
}
.MuiDialogContent-root label {
    display: block;
    margin-bottom: 10px;
}
.MuiDialogContent-root input, .MuiDialogContent-root textarea {
    padding:10px 15px;
    font-size: 15px;
    color: #000;
    border: 1px solid gray!important;
    width: 100%;
    height: 40px;
    margin-bottom: 15px;
}
.MuiDialogContent-root textarea {
    height: 100px;
    margin-bottom: 0;
}
.MuiDialogActions-root {
    padding: 0 15px 20px!important;
    margin-right: 0!important;
}
.MuiDialogActions-root input {
    padding:10px 15px;
    background: #0053a3;
    width: 180px;
    font-size: 16px;
    font-weight: 500;
    color: #ffff;
    outline: none;
    border: none;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    margin-right: 0!important;
}