.css-1u2w381-MuiModal-root-MuiDrawer-root{
    z-index: 1 !important;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper{
    overflow-y: hidden !important;
}

.container-scroll{
    display:block;
    height: 700px;
    overflow-y: scroll;
}

.text-tag{
    display: block;
    width: fit-content;
    margin-left: 3%;
    margin-right: 10px;
}

.Transaction-titel{
    padding-right: 60px;
    margin: 110px 15px 20px;
    color: #000;
    position: relative;
}
.Transaction-titel button {
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}
.Transaction-titel + hr {
    margin:1rem 0 1.4rem;
}
hr {
    margin:.8rem 0;
}
.tag{
    margin-left: 2%;
}

.headP{
    color: gray;
}

.Notes{
    margin-left: 3%;
    margin-top: 10px;
}

.unifieLis {
    list-style: none;
    display: flex;
    flex-flow: column wrap;
    height: 19em;
  }
  
.listt {
    padding: 1em;
    margin: 2px;
  }
.listShop{
    display: block;
}

.Transaction-Images{
    list-style: none;
    display: flex;
    justify-content: space-between;
}
.Transaction-Images .images {
    background: #f5f5f5;
    width: calc(50% - 10px);
    padding: 10px;
    height: 120px;
    position: relative;
}
.Transaction-Images .images img {
    display: block;
    width: 100%;
    height: 100px;
    object-fit: contain;
}
.iconnn{
    position: absolute;
    background-color: white !important;
    color: blue !important;
    bottom: 40px;
    float: right;
    border-radius: 0% !important;
    padding: 8px !important;
}
.transaction-btn-wrap {
    padding:10px 10px 25px;
    text-align: right;
}