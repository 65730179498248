.addbudgetSection{
  padding: 15px 40px;
}
.addbudgetSection > h2 {
  font-size: 20px;
  color: #000;
  margin: 0px 0px 10px 0px;
  font-weight: 600;
  width: 100%;
}

.addbudgetSection .addBudgetForm{
  background: #fff;
  padding: 18px 20px;
  border: 1px solid #D3DAE6;

}
.addbudgetSection .addBudgetForm h4 {
  font-size: 16;
  font-weight: 600;
}
.addbudgetSection .addAdminParticipantForm {
  display: flex;
  margin: 0px -15px;
}
.addbudgetSection .addBudgetForm .participantList{
  width: calc(33% - 30px);
  margin: 0px 10px 20px 0px;
}
.addbudgetSection .addAdminParticipantForm .MuiFormControl-root {
  width: 100%;
}

.addbudgetSection .addBudgetForm .itemCategorySection{
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin-bottom: 20px;
}

.addbudgetSection .addBudgetForm .itemCategorySection > div{
  width: calc(33% - 30px);
  margin-right: 20px;
}

.addbudgetSection .addBudgetForm .storeText{
  margin-bottom: 10px;
}

.addbudgetSection .addBudgetForm .storeData{
  width: calc(33% - 30px);
  margin-right: 20px;
  margin-bottom: 20px;
}

.addbudgetSection .addBudgetForm .supporterText{
  margin-bottom: 10px;
}

.addbudgetSection .addBudgetForm .supporterData{
  display: flex;
  align-items: center;
}
.supporterSearch {
  max-width: 400px;
  margin: 0 0 15px;
}
.supporterSearch .MuiTextField-root {
  padding: 0;
}
.addbudgetSection .addBudgetForm .supporterData .button{
  display: grid;
}

.addbudgetSection .addBudgetForm .supporterData .button button{
  border: 1px solid #D3DAE6;
  background: #D3DAE6;
  border-radius: 5px;
  width: 80px;
  margin: 10px;
  text-transform: capitalize;
  cursor: pointer;
}
.addbudgetSection .addBudgetForm .supporterData .selectedData{
margin-left: 50px;
}
.addbudgetSection .addBudgetForm .supporterData .selectSupportData {
  width: 400px;
  height: 240px;
  padding: 15px;
  border: 1px solid #ddd;
  overflow-y: scroll;
  margin-top: 5px;
  margin-right: 46px;
  border-radius: 5px;
}

.addbudgetSection .addBudgetForm .supporterData .selectSupportData .selected{
  background: #D3DAE6;
  border: 1px solid #D3DAE6;
  cursor: pointer;
}

.addbudgetSection .addBudgetForm .supporterData .selectSupportData span {
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.addbudgetSection .addBudgetForm .issharedToggle .Mui-checked .MuiSwitch-thumb{
  color: #0053A3;
}

.addbudgetSection .btnComponent{
  margin-top: 20px;
  margin-bottom: 20px;
}

.addbudgetSection .btnComponent .addBdgtBtn{
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #0053A3;
  border-radius: 5px;
  background: #0053A3;
  font-size: 14px;
  font-weight: 400;
  color: white;
  text-transform: capitalize;
}

.addbudgetSection .btnComponent .addBdgtBtn .MuiTouchRipple-root{
  display: none;
}

.inputField .MuiFormControl-marginNormal{
  margin-bottom: 0px !important;
}
.addAdminParticipantForm + .MuiDialogActions-root {
  padding-top: 15px!important;
}
.addbudgetSection .addBudgetForm > div {
  position: relative;
}
.addbudgetSection .addBudgetForm .errorField{
  font-size: 13px;
  color: red;
  padding-top: 5px;
  position: absolute
}
@media screen and (max-width:991px) {
  .addbudgetSection .addBudgetForm .itemCategorySection, .addbudgetSection .addBudgetForm .supporterData {
    display: block;
  }
  .addbudgetSection .addBudgetForm .itemCategorySection > div, .addbudgetSection .addBudgetForm .participantList {
    width: 100%;
    margin-top: 10px;
  }
  .addbudgetSection .addBudgetForm .supporterData .selectSupportData {
    width: 100%;
    margin-left: 0!important;
  }
  .addbudgetSection .addBudgetForm .supporterData .button {
    display: inline-flex;
    padding: 10px 0;
  }
  .addbudgetSection .addBudgetForm .supporterData .button button {
    margin-left: 0;
  }
  .addbudgetSection .addBudgetForm .itemCategorySection label {
    display: block;
    margin-bottom: 10px;
  }
  .supporterSearch {
    max-width: 100%;
  }
}
@media screen and (max-width:639px) {
  .addbudgetSection {
    padding-left: 0;
    padding-right: 0;
  }
  .container.addbudgetSection, .addbudgetSection .addBudgetForm {
    padding-left: 15px;
    padding-right: 15px;
  }
  .addbudgetSection .addBudgetForm .errorField {
    position: static;
  }
}
@media screen and (max-width:480px) {
  .budgetSection .pl-5 {
    padding-left: 16px!important;
  }
}
