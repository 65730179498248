@use "../../assets/theme/" as *;

/* Alert*/
.alert{
  line-height: 1;
  border:1px solid $border-color;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 0 ;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
  height: 0px;
  overflow: hidden;
  position:relative;
  transition:opacity 300ms ease-in-out, visibility 300ms ease-in-out, margin 300ms ease-in-out;
  &__has_error{
    background: $secondary;
    border-color:$secondary;
  }
  >span{
    padding: calc(1rem + 2px) 1rem 1rem;
    display: flex;
    align-items: flex-start;
    width:100%;
    height:100%;
    left:0;
    top:0;
    line-height:1.2;
    position: relative;
    span{
      margin-right:10px;
    }
    .close{
      background:none;
      padding:.5rem;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      border:0px;
      img{
        width: 24px;
        height: auto;
      }
    }
  }
  &.show{
    color:$white;
    height: auto;
    margin: 1rem 0;
    opacity:1;
    visibility:visible;
    transform:translateY(0);
  }
}
