.addGuardianSection > h3 {
  font-size: 22px;
  color: #000;
  margin: 0px 0px 10px 0px;
}
.addGuardianSection .addGuardianForm {
  display: flex;
  flex-wrap: wrap;
}

.addGuardianSection .addGuardianForm .MuiFormControl-root {
  width: 100%;
}
.addGuardianSection .addGuardianForm .MuiFormControl-root.dateField {
  margin-top: 16px;
}
.addGuardianSection
  .addGuardianForm
  .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.addGuardianSection .addGuardianForm .MuiInput-underline:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.addGuardianSection .addGuardianForm .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}
.addGuardianSection .addGuardianForm > div {
  width: calc(33.3% - 15px);
  margin-right: 15px;
  margin-bottom: 15px;
}
.addGuardianSection .addGuardianForm > div label {
  display: block;
  margin-bottom: 8px;
}
.addGuardianSection .addGuardianForm > div:nth-child(11) {
  width: calc(33.3% - 15px);
  margin-right: 33.3%;
}

.addGuardianSection .addGuardianForm > div:nth-child(3) {
  width: calc(20% - 15px);
  margin-right: 14%;
}
.addGuardianSection .addGuardianForm > div:nth-child(3) .MuiIconButton-root {
  background: transparent !important;
}
.addGuardianSection
  .addGuardianForm
  > div:nth-child(3)
  .MuiIconButton-root:hover {
  background: transparent;
}
.addGuardianSection
  .addGuardianForm
  > div:nth-child(3)
  .MuiIconButton-root
  .MuiTouchRipple-root {
  display: none;
}
.addGuardianSection .addGuardianForm > div:nth-child(3) svg {
  fill: rgba(0, 0, 0, 0.3);
}

.addGuardianSection .MuiDialogActions-root button {
  border-radius: 4px;
}

.addGuardianSection .MuiDialogActions-root button:nth-child(2) {
  color: #fff !important;
  margin-left: 10px;
}

.addGuardianSection .MuiDialogActions-root button:nth-child(2):hover {
  color: #fff !important;
  margin-left: 10px;
}

.addGuardianParticipantSection > h3 {
  font-size: 22px;
  color: #000;
  margin: 0px 0px 10px 0px;
}
.addGuardianParticipantSection .addGuardianParticipantForm {
  display: flex;
  flex-wrap: wrap;
}

.addGuardianParticipantSection
  .addGuardianParticipantForm
  .MuiFormControl-root {
  width: 100%;
}
.addGuardianParticipantSection .addGuardianParticipantForm > div {
  margin-bottom: 15px;
}
.addGuardianParticipantSection .addGuardianParticipantForm > div label {
  display: block;
  margin-bottom: 5px;
}
.addGuardianParticipantSection
  .addGuardianParticipantForm
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.addGuardianParticipantSection
  .addGuardianParticipantForm
  .MuiInput-underline:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.addGuardianParticipantSection
  .addGuardianParticipantForm
  .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}
.addGuardianParticipantSection .addGuardianParticipantForm > div {
  width: calc(33.3% - 15px);
  margin-right: 15px;
}
.addGuardianParticipantSection .addGuardianParticipantForm > div:nth-child(11) {
  width: calc(33.3% - 15px);
  margin-right: 33.3%;
}

.addGuardianParticipantSection .addGuardianParticipantForm > div:nth-child(3) {
  width: calc(20% - 15px);
  margin-right: 14%;
}
.addGuardianParticipantSection
  .addGuardianParticipantForm
  > div:nth-child(3)
  svg {
  fill: rgba(0, 0, 0, 0.3);
}

.addGuardianParticipantSection .MuiDialogActions-root button {
  border-radius: 4px;
}

.addGuardianParticipantSection .MuiDialogActions-root button:nth-child(2) {
  color: #fff !important;
  margin-left: 10px;
}

.addGuardianParticipantSection
  .MuiDialogActions-root
  button:nth-child(2):hover {
  color: #fff !important;
  margin-left: 10px;
}
.addGuardianParticipantSection.second {
  top: auto;
}
@media screen and (max-width:991px) { 
  .addGuardianSection .addGuardianForm {
    display: block;
  }
  .addGuardianSection .addGuardianForm > div, .addGuardianSection .addGuardianForm .MuiInput-formControl {
    width: 100%!important;
  } 
}