@use '../assets/theme/bh-colors' as *;

.pagination{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  select{
    height: 30px;
    max-width: 70px;
  }
  input{
    height: 30px;
    max-width: 50px;
    text-align: center;
  }

  .items{
    display: flex;
    align-items: center;
    label{
      font-size: 13px;
      color: $black-light;
      white-space: nowrap;
      margin-right: 1rem;
    }
  }
  .paginationNav{
    ul{
      align-items: center;
      display: flex;
      button{
        background: none;
        padding:0px;
        display: flex;
        align-items: center;
        justify-content: center;
        height:28px;
        border:0px;
        cursor: pointer;
        border-radius: 3px;
        color: $black-light;
        svg{
          path{
            fill:$primary;
          }
        }
        &.Mui-selected{
          background: $primary;
          color: $white;
        }
      }
    }
  }

  .goto{
    display: flex;
    align-items: center;
    label{
      font-size: 13px;
      color: $black-light;
      white-space: nowrap;
      margin-right: 1rem;
    }
    .btn{
      margin-left: .5rem;
    }
  }
}
@media screen and (max-width:639px) {
  .pagination {
    .items {
      order: 1;
      label {
        font-size: 12px;
        margin-right: 5px!important;
      }
    }
    .goto {
      order: 2;
      label {
        font-size: 12px;
        margin-right: 5px!important;
      }
      .btn {
        padding-left: 0.50rem;
        padding-right: 0.50rem;
      }
    }
  }
  .paginationNav{
    order: 3;
    width: 100%;
    margin-top: 12px;
    ul {
      justify-content: center;
    }
  }
}
