@use "../../assets/theme/bh-colors" as *;
@use "../../assets/theme/bh-grid" as *;

.dashboard {
  h1 {
    + p {
      font-size: 16px;
      color: $black-light;
    }
  }
  h2,
  h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 1rem 0 0.5rem;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
  }
  .inner {
    > .title {
      font-size: 14px;
      margin-bottom: 10px;
      text-align: center;
    }
  }
  .barFilters {
    padding: 1rem 0 0 1rem;
    .flex {
      justify-content: flex-end;
      select {
        width: auto;
        margin-left: 1rem;
      }
    }
  }
  .barChart {
    padding: 1rem;
  }
  .card {
    background: $white;
    border: 1px solid $border-color;
    border-radius: 5px;
    margin: 10px 0;
    .inner {
      background: rgba($border-color, 30%);
      padding: 1rem;
      height: 100%;
    }
  }
  .participants {
    a {
      border-radius: 5px;
      border: 1px solid $border-color;
      text-align: center;
      padding: 1rem;
      background: $white;
      height: 100%;
      display: flex;
      color: $black;
      text-decoration: none;
      flex-direction: column;
      max-height: 180px;
      margin-bottom: 1rem;
      cursor: pointer;
      &.add {
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: $primary;
        transition: background-color 0.2s ease-in-out;
        span {
          background: $white;
          cursor: pointer;
          width: 60px;
          height: 60px;
          border: 1px solid $primary;
          border-radius: 100%;
          text-indent: -999rem;
          position: relative;
          box-shadow: 0px 3px 4px rgba($white, 0%);
          transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
          &::before,
          &::after {
            content: "";
            width: 24px;
            height: 1px;
            background: $primary;
            border: 0px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &::after {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
        &:hover {
          background: $primary;
          span {
            box-shadow: 0px 3px 8px rgba($white, 70%);
            transform: translateY(-3px);
          }
        }
      }
      .initial {
        width: 60px;
        height: 60px;
        border: 1px solid $primary;
        color: $primary;
        display: flex;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0 auto 10px;
        transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      }
      img {
        display: block;
        margin: 0 auto 0.6rem;
        width: 60px;
        height: 60px;
        object-fit: cover;
        object-position: center;
        box-shadow: 0px 3px 4px rgba($black, 0%);
        transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
        border-radius: 50%;
      }
      strong {
        display: block;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
      &:hover {
        img,
        .initial {
          transform: translateY(-3px);
          box-shadow: 0px 3px 8px rgba($black, 40%);
        }
      }
    }
  }
  .recentTransactions {
    position: relative;
    min-height: 200px;
    .css-16j8fy0 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    a {
      text-decoration: none;
      color: $black;
      position: relative;
      display: block;
      margin-bottom: 2rem;
      .supporterPic {
        position: absolute;
        right: 0;
        top: 0;
        width: 46px;
        height: 46px;
        border-radius: 0px 0px 0px 23px;
        background: $background;
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        img {
          width: 36px;
          height: 36px;
          border-radius: 100%;
          object-position: center;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
        }
        .init {
          width: 36px;
          height: 36px;
          border-radius: 100%;
          background: $primary;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 700;
          transition: transform 0.3s ease-in-out;
        }
      }
      .storePic {
        margin-bottom: 0.5rem;
        height: 170px;
        overflow: hidden;
        border-radius: 5px;
        @media (max-width: $sm) {
          height: auto;
        }
        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
          transition: filter 0.3s ease-in-out;
        }
      }
      .title {
        display: flex;
        justify-content: space-between;
        h4 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: color 0.3s ease-in-out;
        }
        span {
          font-size: 14px;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .description {
        margin: 0.5rem 0 0rem;
        line-height: 18px;
        font-size: 14px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      time {
        font-size: 13px;
        color: rgba($black, 70%);
        transition: color 0.3s ease-in-out;
      }
      &:hover {
        .supporterPic {
          img,
          .init {
            transform: scaleX(-1);
          }
        }
        .storePic {
          img {
            filter: sepia(100%);
          }
        }
        h4 {
          color: $primary;
        }
        time {
          color: $black;
        }
      }
    }
    .loadMore {
      text-align: center;
      a {
        border: 1px solid transparent;
        background: $background;
        padding: 0.35rem 1rem;
        display: inline-block;
        color: $primary;
        text-decoration: underline;
        margin-bottom: 0px;
        border-radius: 5px;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
          border-color 0.2s ease-in-out;
        &:hover {
          background: $primary;
          border-color: $primary;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .dashboard {
    .barFilters {
      .row {
        .col-md-3:first-child {
          width: 40% !important;
        }
      }
    }
  }
  .cardFlex {
    display: block;
    .MuiPaper-root {
      width: auto;
    }
  }
}
@media screen and (max-width: 767px) {
  .dashboard {
    .barFilters {
      .row {
        .col-md-3:first-child {
          width: 100% !important;
          margin-bottom: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .dashboard {
    .barFilters {
      padding-right: 15px;
    }
  }
}
