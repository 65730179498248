@use "./assets/theme/" as *;
@import url("./assets/theme/_common.scss");

body {
  margin: 0;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background !important;
}
.flex {
  display: flex;
}
.align-flex-start {
  align-items: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.width-100 {
  width: 100%;
}
.forTitle {
  justify-content: space-between;
  align-items: center;
}
.spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 101;
  background: rgba($black, 55%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex {
  display: flex;
}
.spaceBetween {
  justify-content: space-between;
}
.gap-10 {
  gap: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
