.addTierSection > h3 {
  font-size: 20px;
  color: #000;
  margin: 0px 0px 10px 0px;
  font-weight: 600;
  width: 100%;
}
.addTierSection .addAdminParticipantForm .MuiFormControl-root {
  max-width: 250px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.addTierSection .MuiDialogActions-root button:nth-child(2) {
  margin-left: 10px;
  color: #fff;
}
.addTierSection .MuiDialogActions-root button:nth-child(2) .MuiButton-label {
  color: #fff;
}
.addAdminParticipantForm {
  margin-bottom: 15px;
}
.formComponent .MuiDialogActions-root button {
  border-radius: 5px;
}
.adminParticipantTable {
  background: #fff;
  padding: 0 15px 15px;
  border: 1px solid #D3DAE6;
}
