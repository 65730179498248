// Declaring left and right padding for cells

$xs: 0;
$sm: 480px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$gutter-x: 0.5rem;

.container-fluid {
  width: 100%;
  padding: 0 $gutter-x * 2;
}
.container {
  max-width: 100%;
  width: 100%;
  padding: 0 $gutter-x * 2;
  margin: 0 auto;
  display: block;
  position: relative;
  @media screen and (min-width: $sm) {
    max-width: 540px;
    padding: 0 $gutter-x;
  }
  @media screen and (min-width: $md) {
    max-width: 720px;
  }
  @media screen and (min-width: $lg) {
    max-width: 960px;
  }
  @media screen and (min-width: $xl) {
    max-width: 1140px;
  }
  @media screen and (min-width: $xxl) {
    max-width: 1200px;
  }
}

[class^="col-"],
[class*="col-"] {
  padding: 0 $gutter-x;
  @media screen and (max-width: $sm) {
    padding: 0 $gutter-x * 2;
  }
}
.g-1 {
  [class^="col-"],
  [class*="col-"] {
    padding: 0 $gutter-x * 0.5;
    @media screen and (max-width: $sm) {
      padding: 0 $gutter-x;
    }
  }
}
.g-2 {
  [class^="col-"],
  [class*="col-"] {
    padding: 0 $gutter-x * 2;
    @media screen and (max-width: $sm) {
      padding: 0 $gutter-x * 2;
    }
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter-x;
  margin-right: -$gutter-x;
  > * {
    flex-shrink: 0;
    width: 100%;
  }
  @media screen and (max-width: $sm) {
    margin-left: -$gutter-x * 2;
    margin-right: -$gutter-x * 2;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
}
.text-center {
  text-align: center;
  img {
    margin: 0 auto;
  }
}
.sec-pad {
  padding: 5rem 0;
  @media screen and (max-width: 768px) {
    padding: 2.5rem 0;
  }
}

// Grid loop
$columns: 12; // Max number of columns
@for $i from 1 through $columns {
  .row > .col-xs-#{$i} {
    flex: 0 0 auto;
    width: calc(100% / $columns * $i);
  }
}
@media (min-width: $sm) {
  @for $i from 1 through $columns {
    .row > .col-sm-#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }
    .sm-offset-#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}
@media (min-width: $md) {
  @for $i from 1 through $columns {
    .row > .col-md-#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }
    .md-offset-#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}
@media (min-width: $lg) {
  @for $i from 1 through $columns {
    .row > .col-lg-#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }
    .lg-offset-#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}
@media (min-width: $xl) {
  @for $i from 1 through $columns {
    .row > .col-xl-#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }
    .xl-offset-#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}
@media (min-width: $xxl) {
  @for $i from 1 through $columns {
    .row > .col-xxl-#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }
    .xxl-offset-#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}
