/******************adminPaymentTable css*********/
.adminPaymentTable {
  background: #fff;
  border: 1px solid #D3DAE6!important;
  padding: 0 15px 15px;
}
.adminPaymentTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  span.viewimageLink {
  display: inline-block;
  font-weight: 600;
  color: #f1574b;
  cursor: pointer;
}
.adminPaymentTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td:first-child {
    text-overflow: inherit;
  }
.adminPaymentTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  span.viewshoppingLink {
  display: inline-block;
  font-weight: 600;
  color: #0053a3;
  cursor: pointer;
}
.adminPaymentTable.guardianPaymentTable .ReactTable .rt-thead .rt-tr .rt-th:nth-child(6),
.adminPaymentTable.guardianPaymentTable .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:nth-child(6){
  flex: 180 0 auto !important;
  width: 180px !important;
}

/*********************************************/

.adminPaymentSection > h2 {
  font-size: 20px;
  margin: 0px;
  font-weight: 600;
  color: #000;
  margin: 0px 0px 15px 0px;
}
.adminPaymentSection .searchTextField {
  width: 250px;
}
/****************************************/

.viewImageScrollModal .MuiDialog-paperWidthSm {
  max-width: 600px;
  width: 100%;
}
.viewImageScrollModal .MuiDialogTitle-root {
  border-bottom: 1px solid #ccc;
}
.viewImageScrollModal .MuiDialogTitle-root h6 {
  font-weight: 600;
  color: #000;
  font-size: 18px;
  line-height: normal;
  padding-bottom: 10px;
}
.viewImageScrollModal .MuiDialogTitle-root button {
  outline: none;
  background: transparent;
  top: 0;
}

.viewImageScrollModal .MuiDialogContent-root .carousel {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}
.viewImageScrollModal .MuiDialogContent-root .carousel .carousel-indicators {
  margin-top: 10px;
  margin-bottom: 0px;
  position: relative;
  display: none;
}
.carousel-control-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
}
.carousel-control-next .sr-only, .carousel-control-prev .sr-only {
  display: none;
}
.carousel-control-next .carousel-control-next-icon::after {
  content: "";
  position: absolute;
  font-family: monospace;
  font-size: 20px;
  right: 9px;
  top: 0px;
  color: #fff;
  z-index: 11;
}
.carousel-control-prev .carousel-control-prev-icon::after {
  content: "";
  position: absolute;
  font-family: monospace;
  font-size: 20px;
  left: 9px;
  top: 0px;
  color: #fff;
  z-index: 11;
  
}
.carousel-control-next {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
}
.carousel-control-prev, .carousel-control-next {
  width: 30px!important;
  top: 50%!important;

}
.viewImageScrollModal .MuiDialogContent-root .carousel .carousel-indicators li {
  border-radius: 50%;
  background: #0053a3;
  width: 15px;
  height: 15px;

  flex: none;
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;
}
.viewImageScrollModal
  .MuiDialogContent-root
  .carousel
  .carousel-inner
  .carousel-item {
  height: 350px;
}
.viewImageScrollModal
  .MuiDialogContent-root
  .carousel
  .carousel-inner
  .carousel-item
  img {
  width: 100%;
  height: 100%;
}
.viewImageScrollModal .MuiDialogContent-root .carousel .carousel-inner > div {
  height: 350px;
}
.viewImageScrollModal
  .MuiDialogContent-root
  .carousel
  .carousel-inner
  img.noImageFound {
  width: 300px;
  height: auto;
  margin: 0px auto;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10px;
}

/****************************************/

.shoppinglistMOdal .MuiDialog-paperWidthSm {
  max-width: 650px;
  width: 100%;
}
.transactionModal .MuiDialog-paperWidthSm {
  max-width: 800px;
  width: 100%;
}
.shoppinglistMOdal .MuiDialogTitle-root, .transactionModal .MuiDialogTitle-root {
  border-bottom: 1px solid #ccc;
}
.shoppinglistMOdal .MuiDialogTitle-root h6, .transactionModal .MuiDialogTitle-root h6 {
  font-weight: 600;
  color: #000;
  font-size: 18px;
  line-height: normal;
  padding-bottom: 10px;
}
.shoppinglistMOdal .MuiDialogTitle-root button, .transactionModal .MuiDialogTitle-root button {
  outline: none;
  background: transparent;
  top: 0;
}
.shoppinglistMOdal .MuiDialogContent-root .hidetable, .transactionModal .MuiDialogContent-root .hidetable {
  display: none;
}
.shoppinglistMOdal .MuiDialogContent-root .showtable, .transactionModal .MuiDialogContent-root .showtable {
  margin: 0px;
  padding: 0px;
  display: flex;
  border: 1px solid #ccc;
  width: 100%;
}

.shoppinglistMOdal .MuiDialogContent-root .showtable li {
  list-style: none;
  padding: 10px 10px;
  color: #000;
  width: calc(50% - 1px);
  border-right: 1px solid #ccc;
  display: flex;
  align-items: center;
}
.shoppinglistMOdal .MuiDialogContent-root .showtable li:last-child {
  border-right: none;
  width: 50%;
}
.shoppinglistMOdal .MuiDialogContent-root .MuiFormControl-root {
  width: 100%;
}
.shoppinglistMOdal .MuiDialogContent-root .shoppingListData {
  display: inline-block;
}
.shoppinglistMOdal
  .MuiDialogContent-root
  .shoppingListData
  .MuiFormControl-root {
  display: flex;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  flex-direction: row;
  width: 100%;
}

.shoppinglistMOdal
  .MuiDialogContent-root
  .shoppingListData
  .MuiFormControl-root
  .MuiMenuItem-root {
  padding: 10px 10px;
  color: #000;
  width: calc(50% - 1px);
  border-right: 1px solid #ccc;
  display: flex;
  align-items: center;
  white-space: normal;
  font-size: 14px;
}
.shoppinglistMOdal
  .MuiDialogContent-root
  .shoppingListData
  .MuiFormControl-root
  .MuiMenuItem-root:last-child {
  border-right: none;
  width: 50%;
}
.shoppinglistMOdal
  .MuiDialogContent-root
  .shoppingListData
  .MuiFormControl-root
  .MuiMenuItem-root:last-child
  .blockbtn {
  margin-right: 0px;
  margin-left: auto;
  border-radius: 6px;
  color: #fff;
  background: #0053a3;
  padding: 5px 10px;
}

/********************************/
.adminPaymentSection .paymentFex {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
}
.adminPaymentSection .paymentFex.transactions-flex .display_inline {
    width: 150px;
    margin-bottom: 15px;
}
.adminPaymentSection .paymentFex.transactions-flex .display_inline button {
  width: 100%;
}
.adminPaymentSection .paymentFex.transactions-flex .MuiFormControl-root:first-child {
  width: 410px;
}
.adminPaymentSection .paymentFex.transactions-flex .MuiFormControl-root .MuiFormControl-root {
  width: auto;
  margin-right: 0;
}
.adminPaymentSection .paymentFex.transactions-flex .MuiFormControl-root:first-child .MuiFormControl-root {
  width: 100%;
}
.adminPaymentSection .paymentFex.transactions-flex .MuiFormControl-root {
  width: 160px;
}
.adminPaymentSection .transactions-flexbox {
  width: calc(100% - 150px);
}
.adminPaymentSection .transactions-flexbox > div.MuiFormControl-root {
  margin-bottom: 15px;
}
.adminPaymentSection .transactions-flexbox .MuiFormControl-marginNormal {
  margin-top: 0;
  margin-bottom: 0;
}
.adminPaymentSection .transactions-flexbox .MuiFormLabel-root {
  font-size: 14px;
}
.adminPaymentSection .paymentFex.transactions-flex {
  align-items: center;
}
.adminPaymentSection .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.adminPaymentSection > div .MuiFormControl-root {
  width: 145px;
  margin-right: 15px;
}
.adminPaymentSection .resetBtn {
  margin-right: 0px;
  margin-left: 0px;
  display: block;
  font-size: 14px;
  color: #fff;
  background: #0053a3;
  cursor: pointer;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.adminPaymentSection .resetBtn:hover {
  color: #fff;
  background: #0053a3;
}
.adminPaymentSection .adminPaymentTable .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td p {
  margin-bottom: 0;
}
.adminPaymentSection .adminPaymentTable .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td .selectCategory input {
  padding:0 4px;
  font-size: 14px;
}
.adminPaymentSection .adminPaymentTable .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td .selectCategory button.MuiAutocomplete-clearIndicator {
  background: #fff;
  color: rgba(0, 0, 0, 0.54);
  padding: 4px;
  margin-right: -2px;
  border-radius: 50%;
}
.adminPaymentSection .adminPaymentTable .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td .selectCategory button.MuiAutocomplete-popupIndicator {
  background: #fff;
  color: rgba(0, 0, 0, 0.54);
  padding: 2px;
  border-radius: 50%;
  margin-right: -2px;
}
.MuiPopover-root .MuiMenuItem-root {
  font-size: 14px !important;
}
/* .adminPaymentSection .paymentFex */
.adminPaymentSection
  .adminPaymentTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  button {
  border-radius: 6px;
  color: #fff;
  background: #0053a3;
  padding: 5px 10px;
  font-size: 12px;
}
.display_inline {
  display: inline-flex;
}
.mr5 {
  margin-right: 5px !important;
}
/**********************************/

.recentHighlightbyid{
  color: #0053a3;
}

@media screen and (max-width:1199px) {
  .adminPaymentSection .paymentFex {
    display: block;
  }
  .adminPaymentSection .transactions-flexbox {
    width: 100%;
    margin-bottom: 15px;
  }
  .adminPaymentSection .paymentFex.transactions-flex .MuiFormControl-root {
    width: 152px;
  }
}
@media screen and (max-width:991px) {
  .adminPaymentSection .paymentFex.transactions-flex .MuiFormControl-root:first-child {
    width: 100%;
  }
  .adminPaymentSection .paymentFex.transactions-flex .MuiFormControl-root {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
