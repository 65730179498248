.profilemodifySection .formComponent .MuiDialogActions-root button {
  border: none !important;
  color: #fff !important;
  border-radius: 4px;
  background: #0053a3 !important;
  min-width: 100px !important;
}

.profilemodifySection .formComponent .MuiDialogActions-root button:hover {
  border: none !important;
  color: #fff !important;
  border-radius: 4px;
  background: #0053a3 !important;
  min-width: 100px !important;
}


/* ------------------- Profile PopUp ---------------------- */

.profilemodifySection .headingEditProfile{
  margin:115px 20px 20px;
  position: relative;
  padding-right: 60px;
}
.profilemodifySection .headingEditProfile button {
  padding: 5px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.editprofile-modal {
  width: 460px;
  overflow-y: scroll;
}
.editprofile-modal .profileImage {
  padding:25px 20px;
  background-color: #f2f4f7;
}
.editprofile-modal .profileImage .image {
  width: 100px;
  margin: 0 auto 15px;
  text-align: center;
}
.editprofile-modal .profileImage .image img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
.editprofile-modal .profileImage .button-wrap {
  text-align: center;
}
.editprofile-modal .profileImage .button-wrap button {
  padding: 0.75rem;
  background: #fff;
  border-radius: 50px;
  margin: 2px 5px;
  outline: none;
  border: none;
  cursor: pointer;
}
.editprofile-modal .profileImage .button-wrap button img {
  width: 16px;
}
.css-1u2w381-MuiModal-root-MuiDrawer-root {
  overflow-y: scroll;
}
.profilemodifySection .formComponent{
  margin: 20px;
  display: block;
}
.profilemodifySection .salesbottomAction button {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.editprofile-modal .formComponent .ProfileConfig > div {
  width: 100%;
  margin-bottom: 15px;
}
.editprofile-modal .formComponent .ProfileConfig > div label {
  display: block;
  margin-bottom: 8px;
}
.editprofile-modal .MuiDialogActions-root {
  padding-left: 0!important;
  padding-right: 0!important;
  padding-top: 15px!important;
}

@media screen and (max-width:567px) {
  .editprofile-modal {
    width: 100%!important;
  }
}