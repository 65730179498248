@use "./bh-colors" as *;

/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* Box sizing rules */
*,
ul,
li,
ol,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family:'Poppins', sans-serif;
}

/*hidden everything on x-axis if greater than 100% */
html {
  overflow-x: hidden;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  // TODO: fix important
  margin: 0;
}
/* Removing focused outline */
* {
  &:focus {
    outline: none;
  }
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

ul {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow: hidden;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}
a{
  text-decoration: none;
}
/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.flex{display: flex;}
.justifyCenter{justify-content: center;}
.content{
  margin-top: 95px;
  padding:1rem 0;
  height: calc(100vh - 95px);
  overflow-y: auto;
  &.adminContent {
    overflow-x: hidden;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: background-color 9999s ease-out, color 9999s ease-out;
    transition: background-color 9999s ease-out, color 9999s ease-out;
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
}
::-webkit-scrollbar{width:16px;height:16px}
::-webkit-scrollbar-thumb{background-color:rgba(105,112,125,.5);background-clip:content-box;border-radius:16px;border:0px solid #f3f3f5}
::-webkit-scrollbar-corner,::-webkit-scrollbar-track{background-color:none}
hr{
  margin:1.5rem 0;
  border-color: rgba($border-color, 50%);
  border-style: solid;
  box-shadow: none;
}
h1,h2,h3,h4,h5,h6{
  font-weight: 600
}
h1{
  font-size: 28px;
}
h2{
  font-size: 24px;
}
h3{
  font-size: 20px;
}
h4{
  font-size: 18px;
}
h5{
  font-size: 16px;
}
h6{
  font-size: 14px;
}
.MuiModal-root {
  z-index: 1!important;
}
.swal-footer {
  text-align: center;
}
.MuiButton-root {
  padding-left: 15px!important;
  padding-right: 15px!important;
  font-weight: 400;
  text-transform: capitalize!important;
}
.MuiFormControl-root {
  background: #fff;
}
.css-1sh8lc {
  border-radius: 5px!important;
  padding: 20px!important;
  .MuiDialogActions-root {
    margin-top: 15px;
    padding-left: 0!important;
    padding-right: 0!important;
    button {
      font-size: 14px!important;
      font-weight: 400!important;
      border-radius: 5px!important;
    }
  }
}
.admin-layout .content {
  margin-top: 0;
  padding: 20px;
}