@use "../../assets/theme/bh-colors" as *;

.editFundsWrapper {
  h2 {
    font-size: 22px;
    margin: 0px 0px 20px;
    font-weight: 600;
    color: #000;
    margin: 0px 0px 10px 0px;
  }
}
