.addBuisnessSection > h3, .Guardianbusinessmodal > h3  {
  font-size: 22px;
  color: #000;
  margin: 0px 0px 10px 0px;
  font-weight: 600;
}
.addBuisnessSection .addBuisnessForm {
  display: flex;
  flex-wrap: wrap;
}
.addBuisnessSection
  .addBuisnessForm
  .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.addBuisnessSection .addBuisnessForm .MuiInput-underline:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.addBuisnessSection .addBuisnessForm .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}
.addBuisnessSection .addBuisnessForm > div {
  width: calc(33.3% - 15px);
  margin-right: 15px;
  margin-bottom: 15px;
}
.addBuisnessSection .addBuisnessForm > div label {
  display: block;
  margin-bottom: 8px;
}
.addBuisnessSection .addBuisnessForm > div > .MuiFormControl-root {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
}
.addBuisnessSection .MuiDialogActions-root button {
  border-radius: 4px;
}

.addBuisnessSection .MuiDialogActions-root button:nth-child(2) {
  color: #fff !important;
  margin-left: 10px;
}

.addBuisnessSection .MuiDialogActions-root button:nth-child(2):hover {
  color: #fff !important;
  margin-left: 10px;
}

.addBuisnessMemberSection > h3 {
  font-size: 22px;
  color: #000;
  margin: 0px 0px 10px 0px;
}
.addBuisnessMemberSection .addBuisnessMemberForm {
  display: flex;
  flex-wrap: wrap;
}

.addBuisnessMemberSection .addBuisnessMemberForm .MuiFormControl-root {
  width: 100%;
}

.addBuisnessMemberSection
  .addBuisnessMemberForm
  .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.addBuisnessMemberSection .addBuisnessMemberForm .MuiInput-underline:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.addBuisnessMemberSection
  .addBuisnessMemberForm
  .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}
.addBuisnessMemberSection .addBuisnessMemberForm > div {
  width: calc(33.3% - 15px);
  margin-right: 15px;
}
.addBuisnessMemberSection .addBuisnessMemberForm > div:nth-child(11) {
  width: calc(33.3% - 15px);
  margin-right: 33.3%;
}

.addBuisnessMemberSection .addBuisnessMemberForm > div:nth-child(3) {
  width: calc(20% - 15px);
  margin-right: 14%;
}
.addBuisnessMemberSection .addBuisnessMemberForm > div:nth-child(3) svg {
  fill: rgba(0, 0, 0, 0.3);
}

.addBuisnessMemberSection .MuiDialogActions-root button {
  border-radius: 4px;
}

.addBuisnessMemberSection .MuiDialogActions-root button:nth-child(2) {
  color: #fff !important;
  margin-left: 10px;
}

.addBuisnessMemberSection .MuiDialogActions-root button:nth-child(2):hover {
  color: #fff !important;
  margin-left: 10px;
}
.Guardianbusinessmodal .MuiDialogActions-root {
  padding-left: 0!important;
  padding-right: 0!important;
}
.Guardianbusinessmodal .MuiDialogActions-root button {
  border-radius: 4px;
}
.Guardianbusinessmodal .MuiDialogActions-root button:last-child {
  margin-left: 15px;
  
}
/* .addBuisnessSection .addBuisnessForm > div:nth-last-child(3) {
  width: 100%;
  margin-top: 15px;
} */
/* .addBuisnessSection .addBuisnessForm > div:nth-last-child(18) {
  width: calc(26% - 15px);
  margin-right: 15px;
} */
.addBuisnessSection .addBuisnessForm > button:nth-last-child(1) {
  /* width: calc(3% - 15px); */
  margin-right: 15px !important;
  color: #fff;
  border-radius: 5px;
  background: #0053a3 !important;
  height: 36px;
  line-height: 15px;
  margin-top: 25px;
}

button.MuiButtonBase-root.MuiIconButton-root.deleteStoreIcon {
  margin-top: 15px;
}


.Guardianbusinessmodal{
  max-width: 600px;
  width: auto!important;
  margin:110px 20px 20px;
  overflow-y:scroll;
}

.addGuardianbusinessform{
  min-height: 800px;
  width: 100%;
  padding-right: 15px;
}
.addGuardianbusinessform .addBuisnessForm {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.addGuardianbusinessform .addBuisnessForm > div {
  width: calc(50% - 10px);
  margin-bottom: 15px;
}
.addGuardianbusinessform .addBuisnessForm .MuiFormControl-root {
  width: 100%;
}
.addGuardianbusinessform .addBuisnessForm label {
  display: block;
  margin-bottom: 8px;
}
@media screen and (max-width:991px) { 
  .addBuisnessSection .addBuisnessForm {
    display: block;
  }
  .addBuisnessSection .addBuisnessForm > div {
    width: 100%;
  } 
}
@media screen and (max-width:639px) {
  .Guardianbusinessmodal {
    width: 300px!important;
  }
  .addGuardianbusinessform .addBuisnessForm {
    display: block;
  }
  .addGuardianbusinessform .addBuisnessForm > div {
    width: 100%;
  }
}