.MuiFormHelperText-root {
    color: red !important;
  }
  .automationModal {
    overflow-y: scroll!important;
    margin-top: 110px;
  }
.automationModal  .automationModalBox  {
  position: static;
  top: auto!important;
  left: auto!important;
  right: auto!important;
  transform: none!important;
  display: flex!important;
  flex-direction: column!important;
  justify-content: center!important;
  margin: 0 auto !important;
  
}
.automationModal  .automationModalBox .row {
  margin-left: 0;
  margin-right: 0;
}
.automationModal  .automationModalBox .title-box {
  position: relative;
  padding-right: 60px;
  margin-bottom: 20px;
}
.automationModal  .automationModalBox .title-box .btn-wrap {
  position: absolute;
  right: 0;
  top: 5px;
  padding: 0;
}
