@use '../assets/theme/bh-colors' as *;

.signInNew{
    position: relative;
    display: flex;
    width: 100vw;
    height: 100vh;
    .noMb{
        margin-bottom: 0px !important;
    }
    .flex{
        height: 100%;
        width: 100vw;
        padding:1rem 2rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        @media (max-width:579px){
            padding:1rem;
            flex-direction: column-reverse;
            justify-content: center;
        }
    }
    .topRow{
        width: 100%;
        justify-content: flex-end;
        display: flex;
        @media (max-width:579px){
            justify-content: center;
        }
        p{
            margin-bottom: 0px;
            @media (max-width:579px){
                width: 100%;
                text-align: center;
                margin-top: 1.5rem;
            }
            .btn{
                margin-left: 10px;
                @media (max-width:579px){
                    display: block;
                    margin: 1rem auto 0;
                }
            }
        }
    }
    .logo{
        margin-bottom: 1.5rem;
        img{
            width: auto;
            max-width: 100%;
            height: auto;
        }
        @media (max-width:579px){
            margin-bottom: 1rem;
        }
    }
    .signInWrap{
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media (max-width:579px){
            flex-grow: 0;
        }
        .innerBox{
            background: $white;
            border:1px solid $border-color;
            padding:40px 30px;
            border-radius: 5px;
            min-width: 440px;
            @media (max-width:579px){
                width: 100%;
                min-width:auto;
                padding:20px;
                flex-grow: 0;
            }
            &.signUp{
                padding:20px 30px;
            }
        }
        h1{
            font-size: 24px;
            text-align: center;
            margin-bottom: 2.5rem;
            font-weight: 600;
            @media (max-width:579px){
             margin-bottom: 1rem;
            }
        }
        ul{
            list-style: none;
            margin: 0;
            padding:0;
            li{
                margin-bottom: 1.5rem;
                &:last-child{
                    margin-bottom: 2.5rem;
                }
                label{
                    display: block;
                    margin-bottom: 4px;
                    font-size: 14px;
                }
                .bh-input-group {
                    .bh-form-control{
                        &:not(:first-child){
                            border-left-color:transparent !important; 
                            margin-left: 0px;
                            padding-left: 0px;
                            outline: 0px;   
                            &:focus{
                                outline: 0px;
                            }
                        }
                        &:not(:last-child){
                            border-right-color:transparent !important; 
                            margin-left: 0px;
                            padding-right: 0px;
                            outline: 0px;   
                            &:focus{
                                outline: 0px;
                            }
                        }
                    }
                    .bh-input-group-text{
                            cursor: pointer;
                        &:not(:last-child){
                            border-right-color:transparent;
                            background: none;
                            padding:.5rem 1rem;
                        }
                        &:not(:first-child){
                            border-left-color:transparent;
                            background: none;
                            padding:.5rem 1rem;
                        }
                    }
                }
            }
        }
        .forgotPass{
            text-align: center;
            a{
                color: $primary;
            }
        }
    }
}