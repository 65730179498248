@use "../../../assets/theme/" as *;

.userInfoWrapper {
  border: 1px solid $border-color;
  min-width: fit-content;
  padding: 20px 20px 0px;
  box-sizing: border-box;

  .orText {
    font-size: 18px;
    text-decoration: underline;
    margin-left: -50px;
  }
  .field-container {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 80px;
    row-gap: 10px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;

    .field-title {
      font-weight: 600;
    }
    .field-value {
      font-size: 16px;
    }
  }
  .button-group {
    margin: 20px 0px 20px;
    display: flex;
    gap: 8px;
  }
}

.input-container {
  form {
    width: 100%;
    display: flex;
    gap: 10px;
  }
}

.budgetTable {
  overflow-y: scroll;
  padding: 0 15px 15px;
  border: 1px solid #d3dae6;
}

.budgetAllocationModal {
  overflow-y: scroll !important;
  margin-top: 20px;
}

.addedFundInfoContainer {
  padding-left: 10px;
  column-gap: 30px;
}
.mlt-10 {
  margin-top: 10px;
  margin-left: 10px;
}

.amountForm {
  margin: 30px 0px;
  display: flex;
  align-items: center;
  .MuiOutlinedInput-root,
  button {
    height: 38px;
  }
}

.MuiButton-containedPrimary {
  background-color: #0053a3 !important;
}
.MuiButton-containedSecondary {
  background-color: #d32f2f !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -10px !important;
}
.w100 {
  width: 100px;
}
.fundInputError {
  position: absolute;
  bottom: -20px;
}
