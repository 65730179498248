.connectedParticipant {
  padding: 30px 15px;
}
.connectedParticipant .ReactTable .rt-thead {
  background: #f4f4fb;
  box-shadow: none;
  border-radius: 4px;
}
.connectedParticipant .ReactTable .rt-thead .rt-tr {
  padding-top: 0px;
}
.connectedParticipant .ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 14px;
}
.connectedParticipant .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none;
}
.connectedParticipant .ReactTable .rt-tbody .rt-tr-group .rt-tr {
  border-radius: 4px;
}
.connectedParticipant .ReactTable .rt-tbody .rt-tr-group:nth-child(2n) .rt-tr {
  background: #f4f4fb !important;
}

.paymentRequestSupporter {
  padding: 30px 15px;
}
.paymentRequestSupporter .ReactTable .rt-thead {
  background: #f4f4fb;
  box-shadow: none;
  border-radius: 4px;
}
.paymentRequestSupporter .ReactTable .rt-thead .rt-tr {
  padding-top: 0px;
}
.paymentRequestSupporter .ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 14px;
}
.paymentRequestSupporter .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none;
}
.paymentRequestSupporter .ReactTable .rt-tbody .rt-tr-group .rt-tr {
  border-radius: 4px;
}
.paymentRequestSupporter
  .ReactTable
  .rt-tbody
  .rt-tr-group:nth-child(2n)
  .rt-tr {
  background: #f4f4fb !important;
}
