.addGuardianParticipantSection{
  padding: 30px;
  top: 80px;
  position: absolute; 
}
.css-1m3p9o7 {
  overflow-y: scroll;
}
.css-1m3p9o7 .addGuardianParticipantSection {
  margin-top: 95px;
  top: auto;
  position: relative;
}
.addParticipantSection > h3 {
  font-size: 22px;
  color: #000;
  margin: 0px 0px 10px 0px;
  font-weight: 600;
}
.css-1m3p9o7 .MuiDialogActions-root {
  padding: 15px 0!important;
}
.css-1m3p9o7 .MuiDialogActions-root button {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.css-1m3p9o7 .addGuardianParticipantSection .user_card {
  padding: 20px;
  background-color: #f2f4f7;
  border: none;
  border-radius: 0;
  margin: 0 -30px 30px;
}
.css-1m3p9o7 .addGuardianParticipantSection .user_card .userPic {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100px!important;
}
.css-1m3p9o7 .addGuardianParticipantSection .user_card .userPic img {
  width: 100px;
  height: 100px;
}
.css-1m3p9o7 .addGuardianParticipantSection .user_card button {
  background: #fff;
  border-radius: 50px;
  margin: 2px 5px;
  cursor: pointer;
}
.css-1m3p9o7 .addGuardianParticipantSection .user_card button img {
  width: 16px;
}
.addParticipantSection .addAdminParticipantForm {
  display: flex;
  flex-wrap: wrap;
}
.addParticipantSection .addAdminParticipantForm > div:nth-child(3),
.addParticipantSection .addAdminParticipantForm > div:nth-child(6),
.addParticipantSection .addAdminParticipantForm > div:nth-child(12) {
  margin-top: 22px;
}
.addParticipantSection .addAdminParticipantForm .MuiFormControl-root {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}
.addParticipantSection .addAdminParticipantForm .MuiFormControl-root.dateField {
  margin-top: 16px;
}
.addParticipantSection
  .addAdminParticipantForm
  .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.addParticipantSection .addAdminParticipantForm .MuiInput-underline:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.addParticipantSection .addAdminParticipantForm .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}
.addParticipantSection .addAdminParticipantForm > div {
  width: calc(33.3% - 15px);
  margin-right: 15px;
  margin-bottom: 15px;
}

.addParticipantSection .addAdminParticipantForm > div:nth-child(3) {
  width: calc(20% - 15px);
  margin-right: 14%;
}
.addParticipantSection .addAdminParticipantForm label {
  display: block;
  margin-bottom: 8px;
}
.addParticipantSection .MuiDialogActions-root {
  padding-left: 0!important;
  padding-right: 0!important;
}
.addParticipantSection
  .addAdminParticipantForm
  > div:nth-child(3)
  .MuiButtonBase-root {
  background: transparent;
}

.addParticipantSection
  .addAdminParticipantForm
  > div:nth-child(3)
  .MuiButtonBase-root
  .MuiTouchRipple-root {
  display: none;
}
.addParticipantSection .addAdminParticipantForm > div:nth-child(3) svg {
  fill: rgba(0, 0, 0, 0.3);
}

.addParticipantSection .MuiDialogActions-root button {
  border-radius: 4px;
}

.addParticipantSection .MuiDialogActions-root button:nth-child(2) {
  color: #fff !important;
  margin-left: 10px;
}

.addParticipantSection .MuiDialogActions-root button:nth-child(2):hover {
  color: #fff !important;
  margin-left: 10px;
}

.addParticipantSection .addAdminParticipantForm > div:nth-child(13) {
  width: 100%;
  margin-top: 15px;
}

.addGuardianParticipantSection > h3 {
  font-size: 22px;
  color: #000;
  margin: 0px 0px 10px 0px;
  font-weight: 600;
}
.addGuardianParticipantSection .addAdminParticipantForm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.addGuardianParticipantSection .addAdminParticipantForm .MuiFormControl-root {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}
.addGuardianParticipantSection .addAdminParticipantForm .MuiFormControl-root.dateField {
  margin-top: 16px;
}
.addGuardianParticipantSection
  .addAdminParticipantForm
  .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.addGuardianParticipantSection .addAdminParticipantForm .MuiInput-underline:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.addGuardianParticipantSection .addAdminParticipantForm .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}
.addGuardianParticipantSection .addAdminParticipantForm > div {
  width: calc(50% - 10px);
  margin-bottom: 15px;
}

.addGuardianParticipantSection
  .addAdminParticipantForm
  > div:nth-child(3)
  .MuiButtonBase-root {
  background: transparent;
}

.addGuardianParticipantSection
  .addAdminParticipantForm
  > div:nth-child(3)
  .MuiButtonBase-root
  .MuiTouchRipple-root {
  display: none;
}
.addGuardianParticipantSection .addAdminParticipantForm > div:nth-child(3) svg {
  fill: rgba(0, 0, 0, 0.3);
}

.addParticipantSection .MuiDialogActions-root button {
  border-radius: 4px;
}

.addParticipantSection .MuiDialogActions-root button:nth-child(2) {
  color: #fff !important;
  margin-left: 10px;
}

.addParticipantSection .MuiDialogActions-root button:nth-child(2):hover {
  color: #fff !important;
  margin-left: 10px;
}

.addParticipantSection .addAdminParticipantForm > div:nth-child(13) {
  width: 100%;
  margin-top: 15px;
}
.css-1m3p9o7 {
  width: 460px!important;
}
.css-1m3p9o7 .addGuardianParticipantSection .addAdminParticipantForm {
  display: block;
}
.css-1m3p9o7 .addGuardianParticipantSection .addAdminParticipantForm > div {
  width: 100%;
}
.css-1m3p9o7 .addGuardianParticipantSection .addAdminParticipantForm > div label {
  display: block;
  margin-bottom: 8px;
}
.payment-sec-wrap .payment-card-item {
  margin-bottom: 20px;
}
.payment-sec-wrap .payment-card-item label {
  display: block;
}
.payment-sec-wrap .payment-card-flex {
  display: flex;
  align-items: center;
  gap: 20px;
}
.payment-sec-wrap .payment-card-flex .MuiButtonBase-root {
  width: 160px;
  background: #0053a3;
  color: #fff;
  padding: 8px 15px;
  min-height: 44px;
}
.payment-sec-wrap .payment-card-flex .item {
  width: 33.333%;
}
.payment-sec-wrap .payment-card-flex .item:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
.payment-sec-wrap .payment-card-flex .item .MuiButtonBase-root {
  margin-top: 15px;
} 
.payment-sec-wrap .container {
  max-width: 100%;
}
@media screen and (max-width:991px) { 
  .addParticipantSection .addAdminParticipantForm {
    display: block;
  }
  .addParticipantSection .addAdminParticipantForm > div {
    width: 100%!important;
  } 
}
@media screen and (max-width:639px) {
  .css-1m3p9o7 {
    width: 100%!important;
  }
  
}
