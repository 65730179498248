.tableWrapper {
  background: #fff;
  border: 1px solid #D3DAE6!important;
  padding: 0 15px 15px;
}
.wrapper > h2 {
  font-size: 20px;
  margin: 0px;
  font-weight: 600;
  color: #000;
  margin: 0px 0px 15px 0px;
}