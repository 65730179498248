.addbudgetSection > h2 {
  font-size: 20px;
  color: #000;
  margin: 0px 0px 10px 0px;
  font-weight: 600;
  width: 100%;
}
.product-header-box {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.product-header-box .MuiFormControl-root {
  width: 300px;
}
.rulesTable {
  background: #fff;
  border: 1px solid #D3DAE6!important;
  padding: 0 15px 15px;
}
.addbudgetSection .addAdminParticipantForm {
  display: flex;
  margin: 0px -15px;
}
.addbudgetSection .addAdminParticipantForm > div {
  width: calc(33% - 30px);
  margin: 0px 15px;
}
.addbudgetSection .addAdminParticipantForm .MuiFormControl-root {
  width: 100%;
}
.addbudgetSection
  .addAdminParticipantForm
  .MuiFormControl-root.MuiFormControl-marginNormal {
  margin: 0px 0px 0px 0px;
}
.addbudgetSection .addAdminParticipantForm {
  margin-bottom: 15px;
}
.addbudgetSection .MuiDialogActions-root button:nth-child(2) {
  color: #fff;
  margin-left: 15px;
}
.addbudgetSection .MuiDialogActions-root button:nth-child(2) .MuiButton-label {
  color: #fff;
}

.rulesSection .addbdgetBtn button {
  margin-left: 10px;
  background: #0053a3;
  color: #fff;
  outline: none;
}
.rulesSection .add {
  display: flex;
  justify-content: flex-end;
  margin-right: 0px;
  margin-left: auto;
  margin-bottom: 20px;
}
.rulesTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  .MuiButtonBase-root.Mui-disabled
  img {
  filter: grayscale(100%);
}
