.connectedSupporterTable {
  padding: 15px;
}
.ReactTable h5 {
  padding-top: 15px;
}
.connectedSupporterTable .ReactTable, .connectedBuisness .ReactTable, .paymentRequest .ReactTable {
  border: none!important;
  padding: 0;
}
.connectedSupporterTable .ReactTable .rt-thead {
  background: #fff;
  box-shadow: none;
  border-radius: 4px;
}
.connectedSupporterTable .ReactTable .rt-thead .rt-tr {
  padding-top: 0px;
}
.connectedSupporterTable .ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 14px;
}
.connectedSupporterTable .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #D3DAE6!important;
}
.connectedSupporterTable .ReactTable .rt-tbody .rt-tr-group .rt-tr {
  border-radius: 4px;
}
.connectedSupporterTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  .MuiButton-root {
  border-radius: 4px;
  color: #fff;
  background: #0053a3;
  text-transform: capitalize;
}
.connectedSupporterTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  .MuiButton-root
  .MuiButton-label {
  line-height: normal;
}
.connectedSupporterTable
  .ReactTable
  .rt-tbody
  .rt-tr-group:nth-child(2n)
  .rt-tr {
  background: #fff !important;
}

.connectedBuisness {
  padding: 15px;
}
.connectedBuisness .ReactTable .rt-thead {
  background: #fff;
  box-shadow: none;
  border-radius: 4px;
}
.connectedBuisness .ReactTable .rt-thead .rt-tr {
  padding-top: 0px;
}
.connectedBuisness .ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 14px;
}
.connectedBuisness .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #D3DAE6!important;
}
.connectedBuisness .ReactTable .rt-tbody .rt-tr-group .rt-tr {
  border-radius: 4px;
}
.connectedBuisness
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  .MuiButton-root {
  border-radius: 4px;
  color: #fff;
  background: #0053a3;
}
.connectedBuisness
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  .MuiButton-root
  .MuiButton-label {
  line-height: normal;
}
.connectedBuisness .ReactTable .rt-tbody .rt-tr-group:nth-child(2n) .rt-tr {
  background: #fff !important;
}

.paymentRequest {
  padding: 15px;
}
.paymentRequest .ReactTable .rt-thead {
  background: #fff;
  box-shadow: none;
  border-radius: 4px;
}
.paymentRequest .ReactTable .rt-thead .rt-tr {
  padding-top: 0px;
}
.paymentRequest .ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 14px;
}
.paymentRequest .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #D3DAE6!important;
}
.paymentRequest .ReactTable .rt-tbody .rt-tr-group .rt-tr {
  border-radius: 4px;
}
.paymentRequest .ReactTable .rt-tbody .rt-tr-group:nth-child(2n) .rt-tr {
  background: #fff !important;
}
.connectedSupporterTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  .viewimageLink {
  color: #0053a3;
  cursor: pointer;
}

.connectedBuisness
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  .viewimageLink {
  color: #0053a3;
  cursor: pointer;
}
.link {
  color: #000;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.link:hover {
  color: #000;
  transform: scale(1.02);
}
.bold {
  font-weight: 600;
}
.blue {
  color: #0053A3

}
