.supporterSection > h3 {
  font-size: 20px;
  margin: 0px;
  font-weight: 600;
  color: #000;
  margin: 0px 0px 15px 0px;
}

.supporterSection .formFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.supporterSection .formFlex .searchTextField {
  width: 250px;
}
.supporterSection .formFlex .searchTextField .MuiFormControl-root {
  width: 100%;
}
.supporterSection .formFlex .MuiButton-root {
  margin-left: 10px;
  background: #0053a3;
  color: #fff;
}

.supporterTab {
  margin-top: 30px;
}
.supporterTab .MuiAppBar-colorPrimary {
  background: transparent !important;
  box-shadow: none !important;
}

.supporterTab .MuiAppBar-colorPrimary .MuiTabs-indicator {
  display: none;
}
.supporterTab .MuiAppBar-colorPrimary .MuiTabs-flexContainer > button {
  font-weight: 600;
  border-radius: 4px 4px 0px 0px;
}
.supporterTab
  .MuiAppBar-colorPrimary
  .MuiTabs-flexContainer
  > button.Mui-selected {
  background: #fff;
  border-radius: 4px 4px 0px 0px;
  color: #0053a3;
  font-weight: 600;
  border: 1px solid #ccc;
  border-bottom: none;
}

.supporterTab .MuiTypography-root {
  border: 1px solid #ccc;
}

.supporterTab .MuiBox-root {
  background: #fff;
}

.adminSupporterTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td:nth-child(2) {
  font-weight: 600;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.supporterTabHeaderProfileDetails .supporterName {
  display: flex;
  align-items: center;
}
.supporterTabHeaderProfileDetails .supporterName .Image {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #ccc;
}
.supporterTabHeaderProfileDetails .supporterName .Image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.supporterTabHeaderProfileDetails .supporterName > h5 {
  font-size: 18px;
  color: #000;
  margin: 0px 0px 0px 0px;
}
.supporterTabHeaderProfileDetails .supporterName .editLink {
  margin-right: 0px;
  margin-left: auto;
}
.supporterTabHeaderProfileDetails .supporterName .editLink button {
  color: #ff952a;
  border: 1px solid #ff952a;
  border-radius: 4px;
  padding: 2px 10px;
  cursor: pointer;
}
.supporterTabHeaderProfileDetails .supporterName .editLink button:hover {
  background: #fff;
}
.supporterTabHeaderProfileDetails .supporterName .editLink button img {
  width: 16px;
  height: auto;
}

.supporterTabHeaderProfileDetails .address {
  font-size: 16px;
  color: #000;
  padding-left: 70px;
  margin-top: -20px;
}

.supporterTabHeaderProfileDetails .supporterInformationDetails {
  margin: 0px;
  padding: 0px;
  display: flex;
  margin-top: 20px;
}
.supporterTabHeaderProfileDetails .supporterInformationDetails li:nth-child(1) {
  width: 20%;
}
.supporterTabHeaderProfileDetails .supporterInformationDetails li {
  list-style: none;
  width: 40%;
}
.supporterTabHeaderProfileDetails .supporterInformationDetails li span {
  display: block;
  color: #000;
  font-size: 16px;
  line-height: normal;
}
.supporterTabHeaderProfileDetails
  .supporterInformationDetails
  li
  span:nth-child(1) {
  margin-bottom: 5px;
  font-weight: 600;
}

/*********************adminSupporterTable css*********/
.supporterSection .adminSupporterTable {
  background: #fff;
  border: 1px solid #D3DAE6!important;
  padding: 0 15px 15px;
}
.supporterSection .adminSupporterTable .statusLink img {
  width: 20px;
  height: auto;
}

.supporterSection
  .adminSupporterTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  .action {
  display: flex;
}

.supporterSection
  .adminSupporterTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  .action
  button {
  display: inline-flex;
}
.supporterSection
  .adminSupporterTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td
  .action
  .statusLink {
  margin-right: 0px;
  margin-left: auto;
}
