@use "./bh-colors" as *;
$transition-time:.2s;
.btn{
    border:1px solid $border-color;
    border-radius: 4px;
    background: $white;
    padding:8.5px 1.5rem;
    font-size: 16px;
    display: inline-block;
    cursor: pointer;
    transition: background-color $transition-time ease-in-out, border-color $transition-time ease-in-out, box-shadow $transition-time ease-in-out;
    box-shadow: 0px 0px 0px rgba($black, .3);
    white-space: nowrap;
    &:hover{
        background: $border-color;
    }
    &.btn-primary{
        background: $primary;
        color: $white;
        border-color: $primary;
        &:hover{
            background: $secondary;
            border-color: $secondary;
            box-shadow: 0px 2px 4px rgba($black, .3);
        }
    }
    &.full{
        width: 100%;
    }
    &.xs{
        padding:5px .75rem;
        font-size: 12px;
    }
    &.sm{
        padding:6.5px 1rem;
        font-size: 14px;
    }
    &.lg{
        padding:12.5px 2rem;
        font-size: 18px;
    }
    &-link{
        color: $primary;
        background: none;
        border:0px;
        display: inline-block;
        font-size: 14px;
        width: auto;
        margin: 0 auto;
        padding:0px;
        text-decoration: underline;
        &:hover{
            background: none;
            text-decoration: none;
            color: $black;
        }
    }
}