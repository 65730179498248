@use "../../../assets/theme/bh-colors" as *;

header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: $black-light;
  z-index: 99;
  [data-toggle="dropdown"] {
    &:hover {
      a {
        img {
          transform: scaleY(1);
        }
      }
      .dropDown {
        opacity: 1;
        visibility: visible;
        pointer-events: unset;
        transform: scaleY(1);
      }
    }
  }
  .flex {
    padding: 0rem 1rem;
    align-items: center;
    .logo {
      padding: 0.5rem 0;
    }
  }
  .divider {
    height: 1px;
    margin: 0;
    background: $border-color;
  }
  .user {
    margin-left: auto;
    ul {
      display: flex;
      max-width: 240px;
      li {
        position: relative;
        &:hover{
          .init{
            transform: rotate(360deg);
            transition: transform 0s ease-in-out;
            color: $black;
          }
        }
      }
      .profile{
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        display: flex;
        align-items: center;
        .init{
          border:1px solid rgba($primary, 30%);
          color:$primary;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          min-width: 40px;
          max-width: 40px;
          height: 40px;
          margin-right: .5rem;
        }
        span{
          display: flex;
          flex-direction: column;
          font-size: 13px;
          line-height: 16px;
        }
        strong{
          white-space: nowrap;
        }
      }
      a {
        display: block;
        padding: 0.35rem;
        &.init {
          background: $green;
          border-radius: 50%;
          font-weight: 600;
          margin-left: 1rem;
          transition: transform 0.4s ease-in-out;
          color: $black;
        }
        img {
          margin-left: 1rem;
        }
      }
    }
  }
  .dropDown {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out,
      top 0.3s ease-in-out, transform 0.3s ease-in-out;
    top: 100%;
    right: 0;
    background: $white;
    box-shadow: -3px 3px 10px rgba($black, 15%);
    border-radius: 6px;
    padding: 4px 0;
    transform: scaleY(0);
    transform-origin: top;
    z-index: 99;
    ul {
      display: flex;
      flex-direction: column;
      a {
        white-space: nowrap;
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        color: $black;
        display: block;
        &:hover {
          color: $primary;
          background: rgba($primary, 5%);
        }
      }
    }
  }
  .breadcrumBar {
    background: $white;
    box-shadow: 0px 0px 16px rgba($black, 10%);
    .quicks{
      margin-left: auto;
      display: flex;
      align-items: center;
      li{
        &:first-child{
          button{
            background: $primary;
            color: $white;
            line-height: 20px;
            font-size: 14px;
            padding:.5rem 1rem;
            border-radius: 4px;
            margin-right: 1rem;
            display: block;
            transition: background-color .3s ease-in-out;
            border:0px;
            cursor: pointer;
            &:hover{
              background: $secondary;
            }
          }
        }
      }
    }
    .quickLinks {
      position: relative;
      > a {
        padding: 0.5rem 0 0.5rem 0.5rem;
        display: flex;
        color: $primary;
        line-height: 24px;
        text-decoration: none;
        img {
          margin-left: 0.5rem;
        }
      }
      .dropDown{
        li{
          &:first-child{
            display: none;
          }
        }
      }
    }
    .hamburger {
      cursor: pointer;
      margin: 0 0.5rem 0 -0.75rem;
      padding: 0.75rem;
      transition: transform .2s ease-in-out;
      &.active{
        transform: scaleX(-1);
      }
    }
    .breadcrumb{
      margin-bottom: 0;
      ul{
        display: flex;
        margin-bottom: 0;
      }
      li{
        display: flex;
        &:last-child{
          span{
            display: block;
            max-width: 160px;
            font-size: 12px;
            clip-path: polygon(0 0,100% 0,100% 100%,0 100%,8px 50%);
            background: rgba($primary, 15%);
            color: $primary;
            border-radius: 0 6px 6px 0;
            padding:.25rem .75rem .25rem 1rem;
          }
        }
        &:first-child{
          a{
            clip-path: polygon(0 0,calc(100% - 8px) 0,100% 50%,calc(100% - 8px) 100%,0 100%);
            border-radius: 6px 0 0 6px;
            padding:.25rem .95rem .25rem .75rem;
          }
        }
        a{
          clip-path: polygon(0 0,calc(100% - 8px) 0,100% 50%,calc(100% - 8px) 100%,0 100%,8px 50%);
          display: block;
          max-width: 160px;
          background: $primary;
          color: $white;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          padding:.25rem 1rem;
          text-decoration: none;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
    ul{
      margin-bottom: 0 !important;
    }
  }
}
.sidebar{
  position: fixed;
  left: 0;
  top: 88px;
  bottom: 0px;
  height: calc(100vh - 88px);
  background: $white;
  box-shadow: inset -3px 0px 10px rgba($black, 10%);
  width: 240px;
  z-index: 98;
  display: flex;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  transform: translateX(-120%);
  &.active{
    transform: translateX(0);
  }
  &:before{
    content: '';
    background:linear-gradient(160deg, rgba(211,218,230,0.3) 0, rgba(211,218,230,0.3) 32px, rgba(255,0,0,0) 0),linear-gradient(175deg, rgba(211,218,230,0.3) 0, rgba(211,218,230,0.3) 16px, rgba(255,0,0,0) 0);
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 40px;
  }
  .sidebarClose{
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(150%, 25%);
    background: #69707D;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color .3s ease-in-out;
    img{
      display: block;
      width: 20px;
      height: 20px;
    }
    &:hover{
      background: $primary;
    }
  }
  nav{
    position: relative;
    z-index: 1;
    flex-grow: 1;
    overflow-y: auto;
    padding:.5rem 0;
    ul{
      display: flex;
      flex-direction: column;
      li{
        padding:1rem 1rem 0;
      }
      a{
        display: flex;
        align-items: center;
        padding:.5rem;
        background: $white;
        font-size: 14px;
        color: $black-light;
        border-radius: 6px;
        transition: color .3s ease-in-out, background-color .3s ease-in-out;
        span{
          display: block;
          margin-right: .5rem  ;
        }
        &:hover{
            background: rgba($primary, 10%);
        }
        &.active{
          color: $white;
          background: $primary;
        }
      }
    }
  }
  .copy{
    padding:.5rem .5rem .5rem 1.5rem;
    font-size: 13px;
    color:rgba($black-light, 60%)
  }
}

.modal{
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  background: transparent;
  transition: background-color .3s ease-in-out;
  &.show{
    visibility: visible;
    opacity:1;
    background: rgba($black, 50%);
    .modal-dialog{
     transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }
  .modal-dialog{
    margin:1.75rem auto;
    max-width: 500px;
    display: flex;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
    transition: transform .5s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out;
    transform: translateY(-25px);
    opacity: 0;
    visibility: hidden;
  }
  .modal-content{
    background: $white;
    border-radius: 6px;
    padding:2rem;
    position: relative;
  }
  .modal-header{
    display: flex;
    justify-content: space-between;
    button{
      position: absolute;
      padding:1rem;
      right: 10px;
      top: 4px;
      cursor: pointer;
      background: none;
      border:0px;
      transition: transform .2s ease-in-out;
      &:hover{
        transform: scale(1.3);
      }
    }
    h5{
      font-size: 20px;
      margin-bottom: .75rem;
    }
  }
  .modal-body{
    .details{
      background: rgba($border-color, 40%);
      margin: 1rem 0;
      border-radius: 6px;
      padding: 1rem;
      .fRow{
        display: flex;
        .item{
          padding:5px 0;
          &:nth-child(1){
            width: 30%;
          }
          &:nth-child(2){
            width: 25px;
          }
          &:nth-child(3){
            flex-grow: 1;
          }
        }
      }
      +p{
        margin-bottom: 1rem;
      }
    }
      .note{
        opacity: .6;
      }
  }
}
@media screen and (max-width:500px) {
  .modal{
    .modal-dialog{
      max-width: inherit;
      margin: 1rem;
    }
    .modal-content{
      padding:1rem;
    }
  }
}
@media screen and (max-width:479px) {
  header .breadcrumBar {
    .quicks li:first-child button{
      display: none;
    }
    .quickLinks .dropDown li:first-child{
      display: block;
    }
  }
  .modal .modal-body .details .fRow{
    flex-direction: column;
    &:not(:last-of-type){
      margin-bottom: 10px;
    }
    .item{
      padding:0px;
      &:nth-child(2){
        display: none;
      }
    }
  }
}