@use "./bh-colors" as *;

// variables
$input-line-height: 1;
$input-border-width: 1px;
$input-height-border: $input-border-width * 2;
// Padding
$input-padding-y: 0.5rem;
$input-padding-x: 0.75rem;
$input-padding-y-xs: 0.3rem;
$input-padding-x-xs: 0.45rem;
$input-padding-y-sm: 0.32rem;
$input-padding-x-sm: 0.5rem;
$input-padding-y-lg: 0.571rem;
$input-padding-x-lg: 1rem;
// Height
$input-height: 44px;
$input-height-sm: 36px;
$input-height-lg: 56px;
$input-height-xs: 28px;
// Font Size
$input-font-size: 1rem;
$input-font-size-xs: 0.7rem;
$input-font-size-sm: 0.8rem;
$input-font-size-lg: 1.25rem;
// Font weight
$input-font-weight: 400;

// Colors
$input-color: $black;
$input-focus-color: $black;
$text-muted: lighten($black, 30%);
$input-placeholder-color: lighten($black, 70%);
// Background colors
$input-bg: $white;
$input-focus-bg: $white;
$form-file-button-color: lighten(#212529, 15%);
// Borders
$input-border-color: $border-color;
$input-focus-border-color: $border-color;
$input-border-radius: 4px;
// Shadow
$input-box-shadow: none;
$input-focus-box-shadow: none;
// Transitions
$input-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
outline-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

// Form labels
$form-label-margin-bottom: 0.25rem;
$form-label-font-size: 13px;
$form-label-font-style: normal;
$form-label-font-weight: 400;
$form-label-color: #67757f;
// Disabled
$input-disabled-bg: #f5f7f9;
$input-disabled-color: rgba($black, 60%);
$input-disabled-border-color: darken(#f5f7f9, 10%);
$form-file-button-hover-bg: darken(#f5f7f9, 10%);

// Form group
.bh-form-group{
  margin-bottom: 1rem;
}
// Form Control
.bh-form-control {
  display: block;
  width: 100%;
  min-height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: inherit;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: border-box;
  border: $input-border-width solid $input-border-color;
  appearance: none;
  box-sizing: border-box;
  border-radius: $input-border-radius;
  box-shadow: $input-box-shadow;
  transition: $input-transition;
  outline: 1px solid transparent;
  &.error{
    border-color:$secondary;
    color: $secondary;
  }
  &[type="file"] {
    overflow: hidden; // prevent pseudo element button overlap
    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }
  &[type="date"] {
    overflow: hidden;
    position: relative;
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      width: auto;
    }
    &:focus {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      outline: 1px solid rgba($primary, 90%);
      box-shadow: $input-focus-box-shadow;
    }
  }
  &[type="range"] {
    margin: 0;
    width: 100%;
    min-height: 10px;
    max-height: 10px;
    padding: 0px;
    border: 0px;
    outline: 0px;
    background-color: rgba($primary, 20%);
    background-image: linear-gradient($primary, $primary);
    background-size: 30% 100%;
    background-repeat: no-repeat;
    &:focus {
      border: 0px;
      outline: none;
      background-color: rgba($primary, 30%);
    }
    &:active {
      &::-webkit-slider-thumb {
        box-shadow: 0px 0px 20px $primary;
        border: 4px solid rgba($white, 20%);
      }
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 10px;
      min-height: 10px;
      max-height: 10px;
      cursor: pointer;
      background: transparent;
      border-radius: 5px;
      border: none;
      box-shadow: none;
      outline: none;
      -webkit-appearance: none;
    }
    &::-moz-range-track {
      width: 100%;
      height: 10px;
      min-height: 10px;
      max-height: 10px;
      cursor: pointer;
      background: transparent;
      border-radius: 5px;
      border: none;
      box-shadow: none;
      outline: none;
      -webkit-appearance: none;
    }
    &::-ms-track {
      width: 100%;
      height: 10px;
      min-height: 10px;
      max-height: 10px;
      cursor: pointer;
      background: transparent;
      border-radius: 5px;
      border: none;
      box-shadow: none;
      outline: none;
      -webkit-appearance: none;
    }
    &::-webkit-slider-thumb {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: $primary;
      cursor: ew-resize;
      -webkit-appearance: none;
      margin-top: -10px;
      box-shadow: 0px 0px 10px $primary;
      border: 4px solid lighten($primary, 50%);
    }
    &::-moz-range-thumb {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: $primary;
      cursor: ew-resize;
      -webkit-appearance: none;
      margin-top: -10px;
      box-shadow: 0px 0px 10px $primary;
      border: 1px solid $primary;
    }
    &::-ms-thumb {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: $primary;
      cursor: ew-resize;
      -webkit-appearance: none;
      margin-top: -10px;
      box-shadow: 0px 0px 10px $primary;
      border: 1px solid $primary;
    }
  }
  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
  }
  // Add some height to date inputs on iOS
  &::-webkit-date-and-time-value {
    height: if(unit($input-line-height) == "", $input-line-height * 1em, $input-line-height);
  }
  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }
  // Disabled and read-only inputs
  &:disabled,
  &[readonly] {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    opacity: 1;
  }

  // File input buttons theming
  &::file-selector-button {
    padding: $input-padding-y $input-padding-x;
    margin: (-$input-padding-y) (-$input-padding-x);
    margin-inline-end: $input-padding-x;
    color: $form-file-button-color;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: $input-border-width;
    border-radius: 0; // stylelint-disable-line property-disallowed-list
    transition: background-color 0.2s ease-in-out;
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: $form-file-button-hover-bg;
  }

  &::-webkit-file-upload-button {
    padding: $input-padding-y $input-padding-x;
    margin: (-$input-padding-y) (-$input-padding-x);
    margin-inline-end: $input-padding-x;
    color: $form-file-button-color;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: $input-border-width;
    border-radius: 0; // stylelint-disable-line property-disallowed-list
  }

  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: $form-file-button-hover-bg;
  }
  &.xs {
    min-height: $input-height-xs;
    padding: $input-padding-y-xs $input-padding-x-xs;
    font-size: $input-font-size-xs;
    &::file-selector-button {
      padding: $input-padding-y-xs $input-padding-x-xs;
      margin: (-$input-padding-y-xs) (-$input-padding-x-xs);
      margin-inline-end: $input-padding-x-xs;
    }
    &::-webkit-file-upload-button {
      padding: $input-padding-y-xs $input-padding-x-xs;
      margin: (-$input-padding-y-xs) (-$input-padding-x-xs);
      margin-inline-end: $input-padding-x-xs;
    }
  }
  &.sm {
    min-height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $input-font-size-sm;
    &::file-selector-button {
      padding: $input-padding-y-sm $input-padding-x-sm;
      margin: (-$input-padding-y-sm) (-$input-padding-x-sm);
      margin-inline-end: $input-padding-x-sm;
    }
    &::-webkit-file-upload-button {
      padding: $input-padding-y-sm $input-padding-x-sm;
      margin: (-$input-padding-y-sm) (-$input-padding-x-sm);
      margin-inline-end: $input-padding-x-sm;
    }
  }
  &.lg {
    min-height: $input-height-lg;
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $input-font-size-lg;
    &::file-selector-button {
      padding: $input-padding-y-lg $input-padding-x-lg;
      margin: (-$input-padding-y-lg) (-$input-padding-x-lg);
      margin-inline-end: $input-padding-x-lg;
    }
    &::-webkit-file-upload-button {
      padding: $input-padding-y-lg $input-padding-x-lg;
      margin: (-$input-padding-y-lg) (-$input-padding-x-lg);
      margin-inline-end: $input-padding-x-lg;
    }
  }
}
// Make sure textareas don't shrink too much when resized
textarea {
  &.bh-form-control {
    min-height: $input-height;
  }

  &.bh-form-control-sm {
    min-height: $input-height-sm;
  }

  &.bh-form-control-lg {
    min-height: $input-height-lg;
  }
}
// Form Labels
.bh-form-label {
  margin-bottom: $form-label-margin-bottom;
  font-size: $form-label-font-size;
  font-style: $form-label-font-style;
  font-weight: $form-label-font-weight;
  color: $form-label-color;
  &.bh-form-label-lg {
    font-size: $form-label-font-size * 1.25;
  }
  &.bh-form-label-sm {
    font-size: $form-label-font-size * 0.85;
  }
  &.bh-form-range-label {
    margin-bottom: 1rem;
  }
}
// Form Select
$form-select-indicator-padding: 2.25rem;
$form-select-indicator: "../svg/select-indicator.svg";
.bh-form-select {
  display: block;
  width: 100%;
  padding: $input-padding-y $form-select-indicator-padding $input-padding-y $input-padding-x;
  // stylelint-disable-next-line property-no-vendor-prefix
  -moz-padding-start: subtract($input-padding-x, 3px);
  font-family: inherit;
  cursor: pointer;
  font-size: $input-font-size;
  min-height: $input-height;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-image: url($form-select-indicator);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 0.75rem;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  box-shadow: $input-box-shadow;
  transition: $input-transition;
  outline: 1px solid transparent;
  appearance: none;

  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
  }

  &[multiple],
  &[size]:not([size="1"]) {
    padding-right: $input-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 none;
  }

  &.xs {
    padding-top: $input-padding-y-xs;
    padding-bottom: $input-padding-y-xs;
    padding-left: $input-padding-x-xs;
    padding-right: $input-padding-x-xs * 4;
    font-size: $input-font-size-xs;
    min-height: $input-height-xs;
    background-position: right 0.45rem center;
  }

  &.sm {
    padding-top: $input-padding-y-sm;
    padding-bottom: $input-padding-y-sm;
    padding-left: $input-padding-x-sm;
    font-size: $input-font-size-sm;
    min-height: $input-height-sm;
  }

  &.lg {
    padding-top: $input-padding-y-lg;
    padding-bottom: $input-padding-y-lg;
    padding-left: $input-padding-x-lg;
    font-size: $input-font-size-lg;
    min-height: $input-height-lg;
  }
}

// Checks and Radios
$line-height-base: 0.5em;
$form-check-input-width: 1em;
$form-check-inline-margin-end: 1rem;
$form-check-min-height: $input-font-size * $input-line-height;
$form-check-padding-start: $form-check-input-width + 0.5em;
$form-check-margin-bottom: 0.125rem;
$form-check-label-color: null;
$form-check-label-cursor: pointer;
$form-check-transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
$form-check-input-active-filter: brightness(90%);
$form-check-input-border-radius: 0.25rem;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $primary;
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-bg-color: $primary;
$form-check-input-checked-border-color: $primary;
$form-check-input-checked-bg-image: "../svg/check-indicator.svg";
$form-check-radio-checked-bg-image: "../svg/radio-indicator.svg";
$form-check-input-indeterminate-bg-color: $primary;
$form-check-input-indeterminate-border-color: $primary;
$form-check-input-disabled-opacity: 0.5;
$form-check-label-disabled-opacity: 0.5;
$form-check-btn-check-disabled-opacity: 0.5;
$form-switch-width: 2em;
$form-check-border-color: $border-color;
$form-switch-border-radius: $form-switch-width;
$form-switch-padding-start: calc($form-switch-width + 0.5em);
$form-switch-transition: background-position 0.2s ease-in-out, background-color 0.2s ease-in-out;
$form-switch-bg-image: '../svg/switch-indicator.svg';
$form-switch-checked-bg-image: "../svg/switch-indicator-active.svg";
$form-switch-checked-bg-position: right center;
$form-check-input-indeterminate-border-color: "../svg/indeterminate-indicator.svg";
.bh-form-check {
  display: block;
  min-height: $form-check-min-height;
  padding-left: $form-check-padding-start;
  margin-bottom: $form-check-margin-bottom;
  .bh-form-check-input {
    float: left;
    margin-left: $form-check-padding-start * -1;
  }
}
.bh-form-check-input {
  width: $form-check-input-width;
  height: $form-check-input-width;
  margin-top: ($form-check-input-width - $line-height-base) * 0.5; // line-height minus check height
  vertical-align: top;
  background-color: $input-bg;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid $form-check-border-color;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  transition: $form-check-transition;
  cursor: $form-check-label-cursor;

  &[type="checkbox"] {
    border-radius: $form-check-input-border-radius;
  }

  &[type="radio"] {
    border-radius: $form-check-radio-border-radius;
  }

  &:active {
    filter: $form-check-input-active-filter;
  }

  &:focus {
    border-color: $form-check-input-focus-border;
    outline: 4px solid rgba($primary, 30%);
    box-shadow: $form-check-input-focus-box-shadow;
  }

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;

    &[type="checkbox"] {
      background-image: url($form-check-input-checked-bg-image);
    }

    &[type="radio"] {
      background-image: url($form-check-radio-checked-bg-image);
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    border-color: $form-check-input-indeterminate-border-color;
    background-image: $form-check-input-indeterminate-border-color;
  }
  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: $form-check-input-disabled-opacity;
  }
  &[disabled],
  &:disabled {
    ~ .bh-form-check-label {
      opacity: $form-check-label-disabled-opacity;
    }
  }
}
.bh-form-check-label {
  color: $form-check-label-color;
  cursor: $form-check-label-cursor;
}

// Switch
.bh-form-switch {
  padding-left: $form-switch-padding-start;
  .bh-form-check-input {
    width: $form-switch-width;
    margin-left: $form-switch-padding-start * -1;
    background-image: url($form-switch-bg-image);
    background-position: left center;
    border-radius: $form-switch-border-radius;
    transition: $form-switch-transition;
    cursor: $form-check-label-cursor;
    &:focus {
      border-color: $form-check-border-color;
    }
    &:checked {
      background-position: $form-switch-checked-bg-position;
      background-image: url($form-switch-checked-bg-image);
      &:focus {
        border-color: $primary;
      }
    }
  }
}
.bh-form-check-inline {
  display: inline-block;
  margin-right: $form-check-inline-margin-end;
}
.bh-btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  cursor: $form-check-label-cursor;
  &[disabled],
  &:disabled {
    + .btn {
      pointer-events: none;
      filter: none;
      opacity: $form-check-btn-check-disabled-opacity;
    }
  }
  + .btn {
    cursor: $form-check-label-cursor;
    border: 1px solid $input-border-color;
    &:hover {
      background: $white;
    }
  }
  &:checked {
    + .btn {
      background: $secondary;
      color: $white;
      border-color: $secondary;
    }
  }
}
// Form Groups
.bh-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  .bh-input-group-text {
    display: flex;
    align-items: center;
    padding: $input-padding-y $input-padding-x;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    text-align: center;
    white-space: nowrap;
    border: $input-border-width solid $form-check-border-color;
    border-radius: $input-border-radius;
    background-color: #e9ecef;
    &.no-bg{
      background: $white;
    }
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  > .bh-form-control,
  > .bh-form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    border: $input-border-width solid $form-check-border-color;
    &:not(:first-child) {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:last-child) {
      margin-right: -1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  > .bh-form-control:focus,
  > .bh-form-select:focus {
    z-index: 3;
  }
  .btn {
    position: relative;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }
}
.bh-radio-group {
display: flex;
  .bh-btn-check {
    + .btn {
      border-radius: 0px;
      margin-right: -1px;
    }
    &:first-of-type {
      + .btn {
        border-radius: 4px 0 0 4px;
      }
    }
    &:last-of-type {
      + .btn {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}
.bh-check-group {
display: flex;
  .bh-btn-check {
    + .btn {
      border-radius: 0px;
      margin-right: -1px;
    }
    &:first-of-type {
      + .btn {
        border-radius: 4px 0 0 4px;
      }
    }
    &:last-of-type {
      + .btn {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}
/* 
** FLoating Labels
*/
$form-floating-height: add(3.5rem, $input-height-border);
$form-floating-line-height: 1.25;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
$form-floating-transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
.bh-form-floating {
  position: relative;
  > .bh-form-control,
  > .bh-form-select {
    height: $form-floating-height;
    line-height: $form-floating-line-height;
  }
  > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: $form-floating-padding-y $form-floating-padding-x;
    pointer-events: none;
    border: $input-border-width solid transparent;
    transform-origin: 0 0;
    transition: $form-floating-transition;
  }
  > .bh-form-control {
    padding: $form-floating-padding-y $form-floating-padding-x;
    &::placeholder {
      color: transparent;
    }
    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }
  > .bh-form-select {
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
  }
  > .bh-form-control:focus,
  > .bh-form-control:not(:placeholder-shown),
  > .bh-form-select {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  > .bh-form-control:-webkit-autofill {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
}
.error{
  .bh-input-group-text, .bh-form-control{
    border-color:$secondary;
    color: $secondary;
  }
}
.no-rt-border{
  border-right-color: transparent !important;
}
.no-lt-border{
  border-left-color: transparent !important;
}