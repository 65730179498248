.participantSection > h2 {
  font-size: 20px;
  margin: 0px;
  font-weight: 600;
  color: #000;
  margin: 0px 0px 10px 0px;
}

.participantSection .formFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.participantSection .formFlex .searchTextField .MuiIconButton-root {
  color: rgba(0, 0, 0, 0.54);
  background-color: #fafafa;
}
.participantSection .formFlex .searchTextField .MuiFormControl-root {
  width: 250px;
  margin-right: 30px;
}
.participantSection .formFlex .searchTextField .MuiInput-input {
  padding: 10px 0px;
}
.participantSection .formFlex button {
  margin-left: 10px;
  background: #0053a3;
  color: #fff;
}
.no-data-found {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.guardianParticipantSection > h2 {
  font-size: 20px;
  margin: 0px;
  font-weight: 600;
  color: #000;
  margin: 0px 0px 10px 0px;
}

.guardianParticipantSection .formFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.guardianParticipantSection .formFlex .searchTextField {
  width: 250px;
}
.guardianParticipantSection .formFlex .searchTextField .MuiFormControl-root {
  width: 100%;
}
.guardianParticipantSection .formFlex button {
  margin-left: 10px;
  background: #0053a3;
  color: #fff;
}
.participantSection .adminParticipantTable {
  background: #fff;
  border: 1px solid #D3DAE6!important;
  padding: 0 15px 15px;
}
/***************************ParticipantTab******************************/
.participantTab {
  margin: 10px 0;
  border-top: 1px solid #D3DAE6;
}
.tabs-links {
  border-bottom: 1px solid #D3DAE6;
  margin-bottom: 20px;
}
.tabs-links .MuiTab-root {
  font-size: 18px;
  font-weight: 600;
  color: #343741;
  opacity: .4;
  text-transform: capitalize;
}
.tabs-links .MuiTab-textColorInherit.Mui-selected {
  color: #0053A3;
}
.tabs-links .MuiTabs-indicator {
  background-color: #0053A3!important;
}
.participantTab .MuiAppBar-colorPrimary {
  background: transparent !important;
  box-shadow: none !important;
}

.participantTab .MuiAppBar-colorPrimary .MuiTabs-indicator {
  display: none;
}
.participantTab .MuiAppBar-colorPrimary .MuiTabs-flexContainer > button {
  font-weight: 600;
  border-radius: 4px 4px 0px 0px;
}
.participantTab
  .MuiAppBar-colorPrimary
  .MuiTabs-flexContainer
  > button.Mui-selected {
  background: #fff;
  border-radius: 4px 4px 0px 0px;
  color: #0053a3;
  font-weight: 600;
  border: 1px solid #ccc;
  border-bottom: none;
}
.participantTab .connectedSupporterTable, 
.participantTab .connectedBuisness,
.participantTab .paymentRequest {
  padding-top: 0;
  background: #fff;
  border: 1px solid #D3DAE6;
}
.tab-filter-box {
  margin-bottom: 20px;
  text-align: right;
}
.tab-filter-box .col-md-8 > .MuiAutocomplete-root {
  max-width: 380px;
}
.tab-filter-box .MuiButton-root {
  background: none;
  font-size: 14px;
  font-weight: 500;
  color: #0053A3;
}
.tab-filter-box .MuiButton-root:hover {
  background: none;
}
.tab-filter-box .MuiButton-root:hover span {
  background: none;
}
.participantTab .MuiBox-root {
  position: relative;
}

.participantTab .MuiBox-root .addsupportBtn {
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background: #0053a3;
  position: absolute;
  right: 0;
  bottom: calc(100% + 10px);
}
.participantTab .MuiBox-root .addsupportBtn:hover {
  background: #0053a3;
  color: #fff;
}
.participantTab .MuiBox-root .addbusinessBtn {
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background: #0053a3;
  position: absolute;
  right: 0;
  bottom: calc(100% + 10px);
}
.participantTab .MuiBox-root .addbusinessBtn:hover {
  background: #0053a3;
  color: #fff;
}
/*************************************************************************/

.participantSection
  .adminParticipantTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td:nth-child(2) {
  font-weight: 600;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.guardianParticipantSection
  .adminParticipantTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td:nth-child(2) {
  font-weight: 600;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.guardianParticipantSection
  .adminParticipantTable
  .ReactTable
  .rt-tbody
  .rt-tr-group
  .rt-tr
  .rt-td:nth-child(7) {
  font-weight: 600;
  font-size: 13px;
  /* text-decoration: underline; */
  cursor: auto;
}
/*************************companyTabHeaderProfileDetails css*******************/

.companyTabHeaderProfileDetails .participantName {
  display: flex;
  align-items: center;
}
.companyTabHeaderProfileDetails .participantName .Image {
  width: 100px!important;
  height: 100px!important;
  margin-right: 20px!important;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}
.companyTabHeaderProfileDetails .participantName .Image img {
  width: 100px!important;
  height: 100px!important;
  border-radius: 50%;
}
.participant-info-detail {
  width: calc(100% - 120px);
  padding-right: 40px;
}
.participant-info-detail h3 {
  font-size: 28px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
}
.participant-info-detail .address {
  color: #343741!important;
}
.participant-info-detail .editLink {
  position: absolute;
  right: 8px;
  top: 5px;
  z-index: 1;
}
.companyTabHeaderProfileDetails .participantName > h5 {
  font-size: 18px;
  color: #000;
  margin: 0px 0px 0px 0px;
}
.companyTabHeaderProfileDetails .participantName .editLink {
  margin-right: 0px;
  margin-left: auto;
}
.companyTabHeaderProfileDetails .participantName .editLink button {
  background: none;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 2px 10px;
  cursor: pointer;
}
.companyTabHeaderProfileDetails .participantName .editLink button:hover {
  background: #fff;
}
.companyTabHeaderProfileDetails .participantName .editLink button img {
  width: 16px;
  height: auto;
}
.companyTabHeaderProfileDetails .participantName .cardDetailBtn {
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background: #0053a3;
  margin-left: 10px;
  height: 30px;
}
.companyTabHeaderProfileDetails
  .participantName
  .cardDetailBtn
  .MuiButton-label {
  line-height: normal;
}
.companyTabHeaderProfileDetails .participantName .cardDetailBtn:hover {
  background: #0053a3;
  color: #fff;
  outline: none;
}


.companyTabHeaderProfileDetails .participantInformationDetails {
  margin: 0px;
  padding: 0px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0!important;
  flex-wrap: wrap;
}
.companyTabHeaderProfileDetails .participantInformationDetails li {
  list-style: none;
  margin-right: 20px;
}
.companyTabHeaderProfileDetails .participantInformationDetails li {
  display: flex;
  align-items: center;
  width: auto!important;
  color: #343741;
  font-size: 14px;
  line-height: normal;
  word-break: break-all;
  word-break: break-word;
}
.companyTabHeaderProfileDetails .participantInformationDetails li .icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F04E98;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-bottom: 0!important;
  border-radius: 5px;
}
.companyTabHeaderProfileDetails .participantInformationDetails li .icon-box svg {
  height: 16px;
}
.companyTabHeaderProfileDetails .participantInformationDetails li .icon-box svg path {
  fill: #fff;
}
.companyTabHeaderProfileDetails
  .participantInformationDetails
  li
  span:nth-child(1) {
  margin-bottom: 5px;
  font-weight: 600;
}
/*******************************************************************************/

/*************************participantDocumentVerifivation table css****************/
.participantDocumentVerifivation {
  width: 80%;
  margin-top: 20px;
  margin-bottom: 50px;
}
.participantDocumentVerifivation table {
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
}

.participantDocumentVerifivation table thead {
  display: block;
  background: rgba(0, 83, 163, 0.2);
}
.participantDocumentVerifivation table thead tr {
  display: flex;
  border-bottom: 1px solid #ccc;
}
.participantDocumentVerifivation table thead tr th:nth-child(1) {
  width: 100px;
  white-space: nowrap;
}
.participantDocumentVerifivation table thead tr th {
  width: calc(50% - 50px);
  padding: 10px 10px;
  display: block;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  border-bottom: none;
  text-align: left;
}
.participantDocumentVerifivation table tbody {
  display: block;
}
.participantDocumentVerifivation table tbody tr {
  display: flex;
  border-bottom: 1px solid #ccc;
}
.participantDocumentVerifivation table tbody tr:nth-child(2n) {
  background: rgba(0, 83, 163, 0.2);
}
.participantDocumentVerifivation table tbody tr:last-child {
  border-bottom: none;
}
.participantDocumentVerifivation table tbody tr td:nth-child(1) {
  width: 100px;
}
.participantDocumentVerifivation table tbody tr td {
  display: block;
  width: calc(50% - 50px);
  font-size: 14px;
  color: #000;
  padding: 10px 10px;
  border-bottom: none;
  word-break: break-all;
  word-break: break-word;
}
.participantDocumentVerifivation h6 {
  font-weight: 600;
}
/**********************************************************************************/

/*****************************cardDetailModal css******************************/

.cardDetailModal .MuiDialog-paperWidthSm {
  width: 100%;
  max-width: 400px;
}
.cardDetailModal .MuiDialog-paperWidthSm .MuiDialogTitle-root > h6 {
  font-weight: 600;
  color: #000;
}
.cardDetailModal .MuiDialog-paperWidthSm .MuiDialogTitle-root {
  border-bottom: 1px solid #ccc;
}

.cardDetailModal
  .MuiDialog-paperWidthSm
  .MuiDialogContent-root
  .MuiFormControl-root {
  width: 100%;
}
.cardDetailModal
  .MuiDialog-paperWidthSm
  .MuiDialogContent-root
  .MuiFormControl-root
  .MuiMenuItem-root {
  padding: 5px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #cccc;
  margin-bottom: 5px;
}
.cardDetailModal
  .MuiDialog-paperWidthSm
  .MuiDialogContent-root
  .MuiFormControl-root
  .MuiMenuItem-root
  > span:first-child {
  width: 120px;
  position: relative;
}
.cardDetailModal
  .MuiDialog-paperWidthSm
  .MuiDialogContent-root
  .MuiFormControl-root
  .MuiMenuItem-root
  > span:first-child::after {
  content: ":";
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 0px;
  color: #000;
  font-weight: 600;
}
.cardDetailModal
  .MuiDialog-paperWidthSm
  .MuiDialogContent-root
  .MuiFormControl-root
  .MuiMenuItem-root
  > span {
  display: inline-block;
  word-break: break-all;
  word-break: break-word;
}
.cardDetailModal
  .MuiDialog-paperWidthSm
  .MuiDialogContent-root
  .MuiFormControl-root
  .MuiMenuItem-root:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}
/*****************************************************************************/

.initial{
  width: 100px;
  height: 100px;
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  margin: 0 auto 10px;
}

@media screen and (max-width:767px) {
  .tab-filter-box .col-md-8 > .MuiAutocomplete-root {
    max-width: 100%;
  }
  .tab-filter-box .MuiButton-root {
    padding-left: 0!important;
    padding-right: 0!important;
  }
}
@media screen and (max-width:639px) {
  .companyTabHeaderProfileDetails .participantName {
    display: block!important;
  }
  .companyTabHeaderProfileDetails .participantName .Image {
    margin: 0 auto 15px!important;
  }
  .participant-info-detail {
    width: 100%;
    padding-right: 0;
  }
  .participant-info-detail h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
}