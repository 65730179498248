
/* .ReactTable .rt-resizer {
  display: none;
  position: unset;
  width: 0px;
  top: 0;
  bottom: 0;
  right: 0px;
  cursor: none;
  z-index: 0;
} */
.ReactTable {
  background-color: #fff;
  border: none!important;
  padding: 0;
}
.ReactTable .rt-thead {
  background-color: #fff;
}
.ReactTable .rt-thead .rt-tr {
  min-height: 45px;
  text-align: left;
  border-bottom: 1px solid #D3DAE6!important;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #D3DAE6!important;
}
.ReactTable .rt-thead .rt-tr .rt-th {
  padding: 5px 10px;
  align-items: center !important;
  display: flex;
  border-right: none;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.ReactTable .bh-form-group {
  margin-bottom: 0!important;
}
.ReactTable .rt-tbody .rt-tr-group .rt-tr {
  min-height: 45px;
  text-align: left;
  background: #fff !important;
}
.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
  padding: 5px 10px;
  align-items: center !important;
  display: block;
  border-right: none;
  font-size: 14px;
  margin: auto 0px;
  color: #000;
}
.ReactTable + .paginationBlock {
  padding-top: 20px;
  flex-wrap: wrap;
}
.paginationBlock .paginationNav .pagination {
  padding: 0px;
  margin: 0px;
  /* width: calc(100% - 100px); */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.ReactTable .rt-thead .rt-tr {
  /* text-align: left; */
  font-weight: bold;
  font-size: 13px;
  height: 40px;
  padding-left: 4px;
  padding-top: 5px;
}
/* 
.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:last-child button {
  visibility: hidden;
  padding: 0px;
}
.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:last-child button:last-child {
  margin: 0px 20px 0px 20px;
} */

.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: none !important;
}
.ReactTable .rt-thead .rt-th.-sort-asc::before {
  content: "^";
  color: #0053A3;
  position: absolute;
  right: 10px;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none !important;
}
.ReactTable .rt-thead .rt-th.-sort-desc::before {
  content: "v";
  color: #0053A3;
  position: absolute;
  right: 10px;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr:hover .rt-td:last-child button {
  visibility: visible;
}

.pagination{
  margin-top: 10px;
}

.paginationBlock .paginationNav .pagination li {
  list-style: none;
}
.paginationBlock .paginationNav {
  display: flex;
  align-content: center;
}
.paginationBlock .paginationNav .MuiFormControl-root {
  min-width: 100px;
}
.paginationBlock .paginationNav .MuiFormControl-root .MuiInputBase-root {
  width: 100%;
}

/*****************pagination*******************/
.paginationBlock {
  display: flex;
  /* align-items: flex-end; */
  justify-content: center;
  justify-content: space-evenly;
}

.paginationBlock .MuiFormControl-root {
  min-width: 70px;
  padding-left: 100px;
  position: relative;
}
.paginationBlock .MuiFormControl-root .MuiInputLabel-formControl {
  position: absolute;
  left: 0;
  top: 50%;
  color: rgba(0, 0, 0, 0.87);
}
.paginationBlock .MuiFormControl-root .MuiInputBase-root {
  min-width: 70px;
}

.paginationBlock p {
  margin: 0px 30px 0px 30px;
  color: rgba(0, 0, 0, 0.87);
}
.paginationBlock p span:first-child {
  display: inline-block;
  margin-right: 3px;
}
.paginationBlock p span:last-child {
  display: inline-block;
  margin-left: 3px;
}
.paginationNav ul li {
  width: 30px;
  height: 30px;
  /* border: 1px solid #ccc; */
  /* border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc; */
  border-radius: none;
}
.paginationNav ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
}
.paginationNav ul li a svg {
  fill: #000;
  opacity: 0.5;
}
.paginationBlock .MuiFormControl-root .MuiInputBase-root{
  width: 70px !important;
  height: 50px !important;
}
.Go-Button{
  /* left: 20px; */
  align-items: flex-end !important; 
  justify-content: center !important;
}
.error {
  font-size: 12px;
  color: red;
  margin-top: 5px;
}
@media screen and (max-width:639px) {
  .paginationBlock .btn {
    padding-left: 0.50rem;
    padding-right: 0.50rem;
  }
}

@media screen and (max-width:479px) {
  .ReactTable + .pagination .items label, .ReactTable + .pagination .goto label {
    font-size: 11px;
  }
  .ReactTable + .pagination .bh-form-select.xs {
    padding-right: 1.4rem;
  }
  .ReactTable + .pagination .goto .btn {
    padding-left: .3rem;
    padding-right: .3rem;
    margin-left: .4rem;
  }
  .ReactTable + .pagination .paginationNav ul li {
    width: 24px;
  }
}


/********************************************/
