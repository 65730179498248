@use "../../../assets/theme/bh-colors" as *;
@use "../../../assets/theme/bh-grid" as *;

.user_card {
  border:1px solid $border-color;
  padding:20px 20px .5rem;
  margin-bottom: $gutter-x * 2;
  border-radius: 5px;
  background: $white;
  display: block;
  position: relative;
  text-align: center;
  button{
    background: none;
    padding:.75rem;
    cursor: pointer;
    display: inline-block;
    border:0px;
    transition:transform .3s ease-in-out;
    &:hover{
      transform: scale(1.2);
    }
    &.edit{
      position: absolute;
      right: 0;
      top: 0px;
    }
  }
  .initial{
    width: 60px;
    height: 60px;
    border:1px solid $primary;
    color: $primary;
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 auto 10px;
  }
  .userPic{
    display: inline-flex;
    img{
      width: 60px;
      height: 60px;
      border-radius: 100%;
      margin: 0 auto 10px;
      object-fit: cover;
      object-position: center;
    }
  }
  .userDetails{
    max-width: 120px;
  }
  .userName{
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .balance{
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}